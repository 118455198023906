import axios from 'axios'
import API from '@/axios'
import ErrorService from '@/error'

const getBaseUrl = () => {
	return '/auth'
}

const PermissionEndpoint = '/permissions/auth/'

const nfe_apiKey = 'wLl3jqTLzuVl2ltOpiehkkmETsS6W18CLoZV4wNsqYD7aWvlqKlhJXeMVVsHMwIOhD1'

const cnpjAPI = axios.create({
	baseURL: 'https://legalentity.api.nfe.io/v1/legalentities',
	timeout: 8000,
})

const cpfAPI = axios.create({
	baseURL: 'https://naturalperson.api.nfe.io/v1/naturalperson',
	timeout: 30000,
})

const usuariosAPI = axios.create({
	baseURL: process.env.VUE_APP_API,
	timeout: 30000,
})

const publicAPI = axios.create({
	baseURL: process.env.VUE_APP_API + getBaseUrl(),
	timeout: 30000,
})

export default {
	async enviarConvites(usuarios, token, organizacao) {
		return await usuariosAPI.post(
			'/usuarios',
			{ usuarios: usuarios },
			{
				headers: {
					Authorization: 'Bearer ' + token,
					'organizacao-id': organizacao,
				},
			}
		)
	},

	async validarCPF(cpf) {
		return await publicAPI.post('/validate/cpf', { cpf: cpf })
	},

	async validateEmail(email, convite = false) {
		return await publicAPI
			.post('/validate/email', { email: email, convite: convite })
			.then((response) => response)
			.catch((error) => error.response)
	},

	async validateEmailInvite(email) {
		return await publicAPI
			.post('/validate/email', { email: email, convite: true })
			.then((response) => response)
			.catch((error) => error.response)
	},

	async validarCNPJ(cnpj) {
		return await publicAPI.post('/validate/cnpj', { cnpj: cnpj })
	},

	async validarCRMV(crmv) {
		return await publicAPI.post('/validate/crmv', { crmv: crmv })
	},

	async validarToken(token) {
		return await publicAPI.post('/validate/token', { token: token })
	},

	async login(payload) {
		try {
			const response = await publicAPI.post('/login', payload);
			console.log("ID do usuário:", response.data.id);  // Aqui imprime o id do usuário
			return response;
		} catch (error) {
			console.error("Erro no login:", error.response);
			throw error;  // Lançar novamente o erro caso precise tratar em outro lugar
		}
	},

	async recuperarSenha(payload) {
		return await publicAPI
			.post('/senha', payload)
			.then((response) => response)
			.catch((error) => error.response)
	},

	async alterarSenha(payload) {
		return await publicAPI
			.put('/senha', payload)
			.then((response) => response)
			.catch((error) => error.response)
	},

	async loginGoogle(payload) {
		return await publicAPI.post('/login/google', payload)
	},

	async confirmarConta(payload) {
		return await publicAPI.post('/enviar-confirmacao', { email: payload })
	},

	async setLead(email) {
		return await publicAPI.post('/lead', email)
	},

	async register(payload) {
		return await publicAPI.post('/registro', payload)
	},

	async confirmarToken(token) {
		return await publicAPI.get('/confirmacao?token=' + token)
	},

	async consultaCNPJ(cnpj) {
		const novo_cnpj = cnpj.replace('/', '').replace('-', '').replace('.', '').replace('.', '')

		return await cnpjAPI.get('/basicInfo/' + novo_cnpj + '?apikey=' + nfe_apiKey)
	},

	async consultaCPF({ cpf, nascimento }) {
		return await cpfAPI
			.get('/status/' + cpf + '/' + nascimento + '?apikey=' + nfe_apiKey)
			.then((response) => response.data)
	},

	async excluirConta(id) {
		return await publicAPI.delete('/me/' + id).then((response) => response.data)
	},

	async getMyPerfil() {
		return await API.get('auth/me/').then((response) => response.data)
	},

	async updateProfile(payload) {
		return await API.post(getBaseUrl() + '/me/', payload, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		}).then((response) => response)
	},

	async getCouncils() {
		return await API.get(getBaseUrl() + '/councils').then((response) => response.data)
	},

	async getPermissions() {
		return await API.get(PermissionEndpoint).then((response) => response.data)
	},
}
