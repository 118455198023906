export default {
	modules: {
		requisicoes: 'Requisições',
		clientes: 'Clientes',
		recipientes: 'Recipientes',
		exames: 'Cadastro de Exames',
		animais: 'Animais',
		demands: 'Demandas',

		auth: {},

		generalize: {
			list: 'Lista',
			painting: 'Quadro',
			timeline: 'Linha do tempo',
			groupByStatus: 'Agrupar por status',
			notStarted: 'Não iniciado',
			started: 'Iniciado',
			concluded: 'Concluido',
		},

		coats: {
			name: 'Pelagens',
			placeholderSearch: 'Buscar pelagens',

			table: {
				coat: 'Pelagem',
				notFound: 'Nenhum registro de pelagens encontrado.',
			},

			form: {
				addCoat: 'Adicionar Pelagem',
				editCoat: 'Editar Pelagem',
				title: 'Adicionar Pelagem',
				coatName: 'Nome da pelagem',
			},

			tooltip: {
				editCoat: 'Editar pelagem',
				removeCoat: 'Remover pelagem',
			},

			alert: {
				title: 'Remover Pelagem',
				subtitle: 'Você tem certeza que deseja remover essa pelagem?',
			},

			feedback: {
				success: {
					create: {
						coat: 'A pelagem foi criada com sucesso.',
					},

					update: {
						coat: 'A pelagem foi atualizada com sucesso.',
					},

					remove: {
						coat: 'A pelagem foi removida com sucesso.',
					},
				},
			},
		},

		menu: {
			tooltip: {
				closeMenu: 'Fechar Menu',
				openMenu: 'Abrir Menu',
			},
		},

		shortcuts: {
			tooltip: {
				newAnimalTutor: 'Novo Paciente/Tutor',
				newClient: 'Novo Cliente',
				newExam: 'Novo Exame',
				examRequest: 'Requisição de Exame',
				newExamRequest: 'Nova Requisição de Exame',
				newOrder: 'Novo Pedido',
				insertResult: 'Inserção de Resultado',
			},
		},

		agreements: {
			name: 'Convênios',
			placeholderSearch: 'Buscar convênios',

			table: {
				headers: {
					creator: 'Criador',
					createdIn: 'Criado Em',
					examName: 'Nome do Exame',
					examPrice: 'Preço do Exame',
					examPriceDiscount: 'Preço com Desconto',
				},

				notFound: 'Nenhum registro de convênios encontrado.',
				notFoundSelect: 'Nenhum exame foi selecionado',
				notFoundExam: 'Nenhum Exame encontrado',
				notFoundSearch: 'Nenhum registro',
			},

			model: {
				name: 'Convênio',
				newAgreement: 'Novo Convênio',
				titleEditAgreement: 'Editar Convênio',
				title: 'Adicionar Convênio',
				descriptionAgreement: 'Descrição do Convênio',
				applyDiscount: 'Aplicar desconto',
				showPriceDiscount: 'Mostrar o preço como desconto?',
				defaultAgreement: 'Convênio Padrão?',
				textDefaultAgreement:
					'Caso selecione este convênio como padrão os\n' +
					'\t\t\t\t\t\t\t\t\t\t\t\t\tpreços seguirão este convênio como\n' +
					'\t\t\t\t\t\t\t\t\t\t\t\t\treferência!',
				selectAllExams: 'Selecionar todos os Exames?',
				valueTables: 'Tabela de Valores',
				addAgreement: 'Adicionar Convênio',
				biggerDiscountText:
					'O Convênio, foi aplicado somente para os exames que foram maior que o desconto',
				percentageBiggerDiscountText:
					'A Porcentagem não foi aplicada, por quê é maior que o preço do exame',
				deleted: 'Este convênio foi excluído',
			},

			tooltip: {
				editAgreement: 'Editar convênio',
				removeAgreement: 'Remover convênio',
				downloadAgreement: 'Baixar convênio',
				unlinkExam: 'Desvincular Exame',
			},

			status: {
				default: 'Convênio Padrão',
				agreementByCustomers: 'Convênio por Cliente(s)',
			},

			feedback: {
				success: {
					create: {
						agreement: 'O Convênio foi criado com sucesso.',
					},

					update: {
						agreement: 'O Convênio foi atualizado com sucesso.',
					},

					remove: {
						agreement: 'O Convênio foi removido com sucesso.',
					},
				},
			},
		},

		exams: {
			name: 'Exames',
			notFound: 'Nenhum registro de exames encontrado.',
			buttonNew: 'Criar Exame',
			placeholder: 'Buscar por Exames...',
			examDefaultName: 'Exame sem nome',
			attributeGroupDefaultName: 'Novo grupo de Atributos',
			attributeDefaultName: 'Novo Atributo',

			attributeTypes: {
				simpleText: 'Texto Simples',
				textarea: 'Texto',
				text: 'Texto',
				integer: 'Inteiro',
				decimal: 'Decimal',
				formula: 'Fórmula',
				date: 'Data',
				positiveNegative: 'Positivo/Negativo',
				image: 'Imagem',
				select: 'Múltipla Escolha',
				method: 'Método',
				percentage: 'Porcentagem',
			},

			bottomSheet: {
				examModels: 'Modelos de Exame',
				selectTemplateToNext: 'Selecione um modelo para avançar',
				clusterVetExamTemplates: 'Modelos De Exames ClusterVet',
				myExamsTemplates: 'Meus Modelos de Exames',
				textReadyModel: 'Modelos prontos para usar. Edite apenas o conteúdo',
				inBlank: 'Em branco',
				select: 'Escolher',
				save: 'Salvar',
				saveAndNew: 'Salvar e Inserir um Novo',
				examRegistration: 'Cadastro de Exame',
				examEdition: 'Edição de Exame',

				tab: {
					exam: 'Exame',
					referenceTables: 'Tabelas de Referências',
					relatedExams: 'Exames Relacionados',
				},

				related: {
					notFound: 'Ainda não existem relacionamentos =(',
					textRelated:
						'Para relacionar um exame a outro, clique no botão abaixo e relacione um atributo a outro exame!',
					linkNewExam: 'Vincular Novo Exame',
					addAnotherRelationship: 'Adicionar outro Relacionamento',
					warning: 'Atenção!',
					textAlert: 'Você só pode relacionar esse exame a partir do cadastro.',
					subTextAlert: 'Deseja salvar esse exame agora?',
					notSave: 'Não',
					saveExam: 'Salvar Exame',
					disableRelated: 'Desativar Relacionamento',
					editRelated: 'Editar Relacionamento',
					unlinkRelated: 'Desvincular Relacionamento',
					examOf: 'Exame de',
					attribute: 'Atributo',
				},

				reference: {
					warning: 'Atenção!',
					textAlert: 'Você só pode referenciar esse exame a partir do cadastro.',
					subTextAlert: 'Deseja salvar esse exame agora?',
					notSave: 'Não',
					saveExam: 'Salvar Exame',
				},

				attribute: {
					name: 'Atributo',
					addOuther: 'Adicionar outro',
					group: 'Grupo',
					or: 'ou',
				},
			},

			form: {
				isTemplate: 'É um modelo?',
				disableStatus: 'Desativar o Exame',
				activeStatus: 'Ativar o Exame',
				editExam: 'Editar Exame',
				unlinkExam: 'Desvincular Exame',
				examName: 'Nome do Exame',
				period: 'Prazo',
				workingDays: 'Dias Úteis',
				hours: 'Horas',
				price: 'Preço',
				reference: 'Referência',
				referenceTooltip: 'Utilizado para identificação externa (Opcional)',
				category: 'Categoria',
				addCategory: 'Adicionar categoria',
				notFoundRecipient: 'Nenhum registro',
				notFoundCategory: 'Nenhum registro',
				notFoundForm: 'Nenhum registro',
				notFoundEquipment: 'Nenhum registro',
				notFoundMethod: 'Nenhum registro',
				notFoundMaterial: 'Nenhum registro',
				notResultForm: 'Nenhum Formulário Encontrado',
				notResultEquipment: 'Nenhum Equipamento Encontrado',
				notResultMethod: 'Nenhum Método Encontrado',
				notResultMaterial: 'Nenhum Material Encontrado',
				recipients: 'Recipientes',
				amountSample: 'Qtd amostras',
				descriptionSample: 'Descrição da Amostra',
				sampleCollectionInstructions: 'Instruções para coleta da amostra',
				technicalManager: 'Patologistas',
				tm: 'Patologistas',
				notFoundTM: 'Você ainda não possui Patologista',
				form: 'Formulário',
				select: 'Selecione',
				equipment: 'Equipamento',
				useds: 'utilizado(s)',
				method: 'Método',
				methodUsed: 'Utilizado (Técnica)',
				methodDescription: 'Método Utilizado [Técnica] (Opcional)',
				material: 'Material',
				materialUsed: 'Material utilizado (Opcional)',
				materialUsedOptional: 'utilizado (Opcional)',
				samples: 'Amostras',
				automatic: 'Automático',
				addRecipient: 'Adicionar recipiente',
				addMaterial: 'Adicionar material',
				addMethod: 'Adicionar método',
				removeExam: 'Remover Exame',
				selectUnit: 'Selecione uma unidade',
			},

			modal: {
				addExamsProfiles: 'Adicionar exames e perfis',
			},

			table: {
				header: {
					sigla: 'Sigla',
					examName: 'Nome do exame',
					price: 'Preço',
					period: 'Prazo',
					method: 'Método',
					status: 'Status',
				},
			},

			referenceTable: {
				warning: 'Atenção',
				doNotHaveReferences: 'Este Exame ainda não possui tabelas de referências',
				canSaveLikeThat:
					'Você poderá salvar desta forma, mas o exame será salvo com status inativo.',

				buttons: {
					yes: 'Sim, salvar mesmo assim',
					no: 'Não, voltar para a edição',
				},

				referenceNote:
					'Aqui você pode configurar valores de referência para comparar nos resultados de seus exames.',
				createNewTable: 'Criar Nova Tabela',
				searchRefTable: 'Buscar tabela de referências',
				learnMore: 'Saiba mais',

				table: {
					exam: 'Exame',
					specie: 'Espécie',
					refTable: 'Tabela de referência',
					ageGroup: 'Faixa etária',
					noRegisterFound: 'Nenhum registro de referências encontrado.',
					from: 'De',
					to: 'Até',
					years: 'anos',
					months: 'meses',
				},

				form: {
					addReferences: 'Adicionar Referências',
					editReferences: 'Editar Referência',
					selectExamNote:
						'Selecione o exame que deseja criar a tabela de referência, depois adicione os valores de atributos correspondentes à espécie.',
					messageObservation:
						'Obs: Esse exame só vai ser referenciado para o paciente com essa idade.',
					name: 'Nome *',
					specie: 'Espécie *',
					initialAge: 'Idade Inicial *',
					finalAge: 'Idade final *',
					periodIn: 'Período em',
					notFoundSpecie: 'Nenhuma espécie encontrada',
					noRecord: 'Nenhum registro',
					years: 'Anos',
					months: 'Meses',
					insertConditional: 'Inserir Condicionais',

					header: {
						attribute: 'Atributo',
						complement: 'Complemento',
						minRef: 'Ref. Mínimo',
						maxRef: 'Ref. Máximo',
						unit: 'Unidade',
					},

					label: {
						nameTable: 'Nome para identificação da tabela',
						selectSpecie: 'Selecione a espécie',
					},
				},
			},

			alert: {
				title: 'Remover Exame',
				subtitle: 'Tem certeza de que deseja remover este exame?',

				discardTitle: 'Descartar Alterações',
				discardSubtitle: 'você tem certeza que deseja descartar essas alterações?',
			},

			feedback: {
				success: {
					create: {
						exam: 'O exame foi criado com sucesso.',
					},

					update: {
						exam: 'O exame foi atualizado com sucesso.',
						examStatus: 'O status do exame foi atualizado.',
					},

					remove: {
						exam: 'O exame foi removido com sucesso.',
					},
				},

				errors: {
					EXAM_HAS_REQUESTED_EXAM_RELATIONSHIPS: {
						title: 'Exame já está sendo utilizado.',
						message: 'O exame não pode ser deletado pois já está sendo utilizado.',
					},
				},
			},
		},

		examsGroup: {
			name: 'Perfil',

			feedback: {
				success: {
					create: {
						examsGroup: 'O perfil foi criado com sucesso.',
					},

					update: {
						examsGroup: 'O perfil foi atualizado com sucesso.',
						examsGroupStatus: 'O status do perfil foi atualizado.',
					},

					remove: {
						examsGroup: 'O perfil foi removido com sucesso.',
						unlinkExam: 'O exame foi desvinculado com sucesso.',
					},
				},
			},
		},

		category: {
			name: 'Categorias',
			title: 'Você sabia que você pode agrupar seus exames por categorias?',
			searchExamsChips: 'Busque exames relacionados',

			addCategory: 'Adicionar categoria',

			table: {
				notFound: 'Nenhum registro de categorias encontrado.',
				category: 'Categoria',
				exams: 'Exames',
			},

			form: {
				addCategory: 'Adicionar Categoria',
				editCategory: 'Editar Categoria',
				categoryName: 'Nome da categoria',
			},

			tooltip: {
				editCategory: 'Editar categoria',
				removeCategory: 'Remover categoria',
				notFoundExam: 'Nenhum exame',
				examCategory: 'Categoria do Exame',
			},

			alert: {
				title: 'Excluir Categoria',
				subtitle: 'Você tem certeza que deseja remover essa categoria?',
			},

			feedback: {
				success: {
					create: {
						category: 'Categoria criada com sucesso',
					},

					update: {
						category: 'Categoria atualizada com sucesso',
					},

					remove: {
						category: 'Categoria removida com sucesso',
					},
				},
			},
		},

		species: {
			name: 'Espécies',
			noAnimal: 'Nenhum animal',
			addSpecie: 'Adicionar Espécie',

			table: {
				specie: 'Espécie',
				notFound: 'Nenhum registro de especies encontrado.',
			},

			form: {
				addSpecie: 'Adicionar Espécie',
				editSpecie: 'Editar Espécie',
				specieName: 'Nome da espécie',
			},

			tooltip: {
				editSpecie: 'Editar espécie',
				removeSpecie: 'Remover espécie',
			},

			alert: {
				title: 'Remover Espécie',
				subtitle: 'Você tem certeza que deseja remover essa espécie?',
			},

			feedback: {
				success: {
					create: {
						specie: 'A espécie foi criada com sucesso.',
					},

					update: {
						specie: 'A espécie foi atualizada com sucesso.',
					},

					remove: {
						specie: 'A espécie foi removida com sucesso.',
					},
				},
			},
		},

		materials: {
			name: 'Materiais',
			descriptionPlan: 'Gerencie os materiais que serão usados no cadastro de Exames.',

			addMaterial: 'Adicionar Material',

			form: {
				title: 'Adicionar Material',
				titleEdit: 'Editar Material',
				addMaterial: 'Adicionar Material',
				materialName: 'Nome do Material',
			},

			table: {
				notFound: 'Nenhum registro de materiais encontrado.',
			},

			tooltip: {
				editMaterial: 'Editar material',
				removeMaterial: 'Remover material',
			},

			alert: {
				title: 'Remover Material',
				subtitle: 'Você tem certeza que deseja remover essa material?',
			},

			feedback: {
				success: {
					create: {
						material: 'Material criado com sucesso',
					},

					update: {
						material: 'Material atualizado com sucesso',
					},

					remove: {
						material: 'Material removido com sucesso',
					},
				},
			},
		},

		races: {
			name: 'Raças',

			addRace: 'Adicionar Raça',

			form: {
				title: 'Adicionar Raça',
				titleEdit: 'Editar Raça',
				addRace: 'Adicionar Raça',
				existRace: 'Já existe uma raça com esse nome',
				raceName: 'Nome da raça',
				specie: 'Espécie',
				specieNotFound: 'Nenhuma Espécie Encontrado',
				minimumWeight: 'Peso mínimo (Opcional)',
				minimum: 'mínimo',
				maximumWeight: 'Peso máximo (Opcional)',
				maximum: 'máximo',
			},

			table: {
				specie: 'Espécie',
				breed: 'Raça',
				notFound: 'Nenhum registro de raças encontrado.',
			},

			tooltip: {
				editRace: 'Editar raça',
				removeRace: 'Remover raça',
			},

			alert: {
				title: 'Remover Raça',
				subtitle: 'Você tem certeza que deseja remover essa raça?',
			},

			feedback: {
				success: {
					create: {
						breed: 'A raça foi criada com sucesso.',
					},

					update: {
						breed: 'A raça foi atualizada com sucesso.',
					},

					remove: {
						breed: 'A raça foi removida com sucesso.',
					},
				},
			},
		},

		address: {
			form: {
				addAddress: 'Adicionar Endereço',
				editAddress: 'Editar Endereço',
				identifyAddress: 'Como quer identificar este endereço?',
				note: 'Dica:',
				facilityText: 'Isso vai ajudar à facilitar sua busca por serviços ou divulgação',
				address: 'Logradouro',
				state: 'UF',
				number: 'Número',
				city: 'Cidade',
				neighborhood: 'Bairro',
				complement: 'Complemento',
				zipCode: 'CEP',
			},
		},

		profiles: {
			name: 'Perfis',
			notFound: 'Nenhum registro de perfis encontrado.',
			buttonNew: 'Criar Perfil',
			placeholder: 'Buscar por Perfis...',

			form: {
				editProfile: 'Editar perfil',
				newProfile: 'Novo Perfil',
				examsPartProfile: 'Exames que fazem parte do perfil',
				discount: 'Desconto',
				totalProfileValue: 'Valor total do Perfil',
				addProfile: 'Adicionar perfil',
				removeProfile: 'Remover Perfil',
			},

			table: {
				header: {
					sigla: 'Sigla',
					profileName: 'Nome do perfil',
					description: 'Descrição',
					price: 'Valor (R$)',
					status: 'Status',
				},
			},

			alert: {
				title: 'Desvincular Exame?',
				subtitle: 'Você tem certeza que deseja desvincular o exame, dentro desse perfil?',
				titleRemoveProfile: 'Remover Perfil',
				subtitleRemoveProfile: 'Você deseja remover este perfil?',
			},

			tooltip: {
				activeStatusExamGroup: 'Ativar Perfil',
				disabledStatusExamGroup: 'Desativar Perfil',
			},
		},

		requests: {
			name: 'Requisição',
			request: 'Requisição',

			table: {
				header: {
					numberRequest: 'Nº de Requisição',
					requester: 'Requisitante',
					patient: 'Paciente',
					progress: 'Progresso',
					start: 'D.H. Início',
					request: 'D.H. Requisição',

					exams: {
						sigle: 'Sigla',
						exam: 'Exame',
						andament: 'Andamento',
						rt: 'R.T',
						recipients: 'Recipientes',
						period: 'Prazo',
					},
				},
				insertResult: 'Inserir Resultado',
				viewRequest: 'View Request',
				searchExamPlaceHolder: 'Buscar por exames...',
				noOwnerFound: 'Nenhum proprietário encontrado.',
				noAnimalFound: 'Nenhum animal encontrado.',
				noDoctorFound: 'Nenhum médico encontrado.',
				noTagFound: 'Nenhum rótulo encontrado.',
				noLaboratoryFound: 'Nenhum laboratório encontrado.',
				notStarted: 'Não foi iniciado',
				notFound: 'Nenhum registro de requisições encontrado.',
				search: 'Buscar Requisições',
				selectCriterFilter: 'Selecione um critério de filtragem',
				newRequest: 'Nova Requisição',
				newOrder: 'Novo Pedido',
				filterByDate: 'Filtro por Data',
				andaments: {
					name: 'Andamento',
					draft: 'Rascunho',
					new: 'Novo',
					newItem: 'Novo',
					waitingForRoute: 'Aguardando rota',
					inTransit: 'Em trânsito',
					noncompliance: 'Não confirmidade',
					inProgress: 'Em progresso',
					processing: 'Processando',
					finished: 'Finalizada',
					analyse: 'Em análise',
					typed: 'Digitado',
					released: 'Liberado',
					waiting: 'Aguardando',
					canceled: 'Cancelada',
				},
				criters: {
					examProgress: 'Andamentos dos exames',
					RequestProgress: 'Progresso da Requisições',
					examLabels: 'Rótulos dos exames',
					urgentRequests: 'Requisições urgentes',
				},
				progress: {
					canceled: 'Cancelada',
					waitingForSample: 'Aguardando amostra',
					prevented: 'Impedido',
					processing: 'Processando',
					concluded: 'Concluído',
				},
				notHaveProfilesExams: 'Esse laboratório não possui perfis ou exames',
				noResultsFilter: 'Nenhum resultado para esse filtro',
			},

			checkout: {
				order: 'Pedido',
				infoPatient: 'Informações do Paciente',
				patient: 'Paciente',
				retractCart: 'Retrair carrinho',
				expandCart: 'Expandir carrinho',
				allPrice: 'Total',
				allPriceDiscount: 'Total',
				discount: 'Desc',
				valueAll: 'Valor Total',
				discountValue: 'Valor do Desconto',
				totalDiscount: 'Total com Desconto',

				tooltip: {
					descriptionBelongsExam: 'Esse exame pertence ao perfil',
				},
				examDescription: 'Descrição do Exame',

				alert: {
					descriptionErrorDoctor:
						'Esse laboratório não possui clientes do tipo médico para fazer a requisição.',
					descriptionErrorCustomers:
						'Você não possui clientes, por favor adicione clientes do tipo médico para conseguir fazer a requisição.',
				},
			},
		},

		receiving: {
			name: 'Recebimento',
			viewRequest: 'Visualizar requisição',
			editRequest: 'Editar requisição',
			removeRequest: 'Remover requisição',
			cancelRequest: 'Cancelar requisição',
			cancelSample: 'Cancelar amostra',
			report: 'Relatório',
			notStarted: 'Não iniciado',
			inProgress: 'Em andamento',
			finished: 'Finalizada',
			notFound: 'Nenhum registro de fatura encontrado',

			header: {
				draftMode: 'Modo Rascunho',
				saving: 'Salvando...',
				done: 'Concluir',
				newRequest: 'Nova Requisição',
			},

			tabs: {
				notes: 'Observações',
				exams: 'Exames',
				requests: 'Requisições',
				logistic: 'Logística',
				payments: 'Pagamentos',
				billings: 'Faturamento',
			},

			tabContent: {
				notes: {},
				exams: {
					addExamOrProfile: 'Adicionar Exames/Perfis',
				},
				logistic: {
					addShipping: 'Adicionar Frete',
				},
				payments: {
					addPayment: 'Adicionar Pagamento',
				},
				billings: {
					addBilling: 'Adicionar Faturamento',
				},
			},

			sidebar: {
				samples: 'Amostras do Paciente',
				addSample: 'Adicionar amostra',
				forms: 'Formulários',
				lastRequests: 'Últimas requisições:',
				answerForm: 'Responder',
			},

			form: {
				title: 'Nova requisição',
				subtitle: 'Selecione os campos que você deseja copiar para a nova requisição',
				order: 'Ordem',
				veterinarian: 'Médico Veterinário',
				patient: 'Paciente',
				samples: 'Amostras',
				exams: 'Exames',
				logistics: 'Logística',
				attributes: 'Atributos',
			},

			attachmentForm: {
				title: 'Adicionar anexo',
				titleForm: 'Título',
				description: 'Descrição',
				attach: 'Anexar',
				relate: 'Relacionar',
			},

			sampleForm: {
				title: 'Adicionar amostra',
				titleEdit: 'Editar amostra',
				details: 'Detalhes',
				material: 'Material',
				recipient: 'Recipiente',
				volume: 'Volume',
				unitOfMeasurement: 'Unidade de medida',
				deadline: 'Prazo',
				deadlineType: 'Tipo de prazo',
				sampleOrigin: 'Origem da amostra',
				exams: 'Exames | {count} Exame | {count} Exames',
				samples: 'Subamostras | {count} Subamostra | {count} Subamostras',
				attachments: 'Anexos | {count} Anexo | {count} Anexos',
				relateExam: 'Relacionar exames',
				relateSample: 'Relacionar amostras',
				reportMessage:
					'O relatório será adicionado em todos os exames vinculados à amostra',
				alert: {
					title: 'Remover relacionamento de amostras',
					subtitle:
						'Para criar {count} amostras, os relacionamentos de amostras serão removidos, deseja continuar?',
				},
				tooltip: {
					amountToCreate1:
						'Quantidade de amostras a serem criadas. Caso o valor for maior que 1,',
					amountToCreate2: 'não será possível relacionar amostras filhas',
				},
			},

			logisticForm: {
				title: 'Registrar coleta',
				samples: 'Amostras',
				shipping: 'Frete',
				provider: 'Fornecedor',
				status: 'Status',
				cost: 'Valor',
			},

			paymentForm: {
				title: 'Adicionar cobrança',
				editPayment: 'Editar cobrança',
				payer: 'Pagante',
				value: 'Valor',
				attachment: 'Anexo',
				relate: 'Relacionar',
				transaction: 'Transação',
				titleAttachment: 'Descreva o comprovante',
				descriptionAttachment: 'Observações',
				receipt: 'Comprovante',
			},

			billingForm: {
				title: 'Nova Fatura',
			},

			selectOrderForm: {
				title: 'Selecionar Pedido',
				order: 'Pedido',
				observation: 'Observação',
				clinic: 'Clínica',
				selectClinic: 'Selecione o cliente',
				selectClinic2: 'Selecione o cliente responsável pelo pedido',
			},

			requestStatus: {
				draft: 'Rascunho',
				draftPrefix: 'Rascunho',
				new: 'Novo',
				newPrefix: 'Novo',
				waitingForRoute: 'Aguardando rota',
				waitingForRoutePrefix: 'Aguard. rota',
				inTransit: 'Em trânsito',
				inTransitPrefix: 'Em trânsito',
				nonCompliance: 'Não conformidade',
				nonCompliancePrefix: 'NC',
				inProgress: 'Em progresso',
				inProgressPrefix: 'Em progresso',
				finished: 'Finalizada',
				finishedPrefix: 'Finalizada',
				canceled: 'Cancelada',
				canceledPrefix: 'Cancelada',
				awaitingSample: 'Aguardando Amostra',
				awaitingSamplePrefix: 'Aguardando Amostra',
				received: 'Recebida',
				receivedPrefix: 'Recebida',
				processing: 'Processando',
				processingPrefix: 'Processando',
				waitingForAnalysis: 'Aguardando análise',
				waitingForAnalysisPrefix: 'Aguardando análise',
				awaitingAuthorization: 'Aguardando Autorização',
				awaitingAuthorizationPrefix: 'Aguardando Autorização',
				inAnalysis: 'Em Análise',
				inAnalysisPrefix: 'Em Análise',
				typing: 'Em Digitação',
				typingPrefix: 'Em Digitação',
				inValidation: 'Em validação',
				inValidationPrefix: 'Em validação',
				awaitingRelease: 'Aguardando Liberação',
				awaitingReleasePrefix: 'Aguardando Liberação',
				sent: 'Enviado',
				sentPrefix: 'Enviado',
			},

			logisticStatus: {
				notAssigned: 'Não Atribuído',
				assigned: 'Atribuído',
				accepted: 'Aceito',
				onRoute: 'Em Deslocamento',
				finished: 'Finalizado',
				canceled: 'Cancelada',
			},

			sampleStatus: {
				onSchedule: 'Em Agendamento',
				scheduledCollection: 'Coleta Agendada',
				inTransit: 'Em Trânsito',
				nonCompliance: 'Não-Conformidade',
				collected: 'Coletada',
				received: 'Recebida',
				discarted: 'Descartada',
				awaitingSample: 'Aguardando Amostra',
				inAnalysis: 'Em análise',
				canceled: 'Cancelada',
				expired: 'Vencida',
				archived: 'Arquivada',
			},

			alerts: {
				success: {
					requestCreated: 'Requisição criada com sucesso',
					requestUpdated: 'Requisição atualizada com sucesso',
					requestCanceled: 'Requisição cancelada com sucesso',
					sampleCanceled: 'Amostra cancelada com sucesso',
					observationCreated: 'Observação criada com sucesso',
					observationUpdated: 'Observação atualizada com sucesso',
					observationDeleted: 'Observação removida com sucesso',
					examAdded: 'Exame adicionado com sucesso',
					examUpdated: 'Exame atualizado com sucesso',
					examDeleted: 'Exame removido com sucesso',
					payerAdded: 'Pagador(a) adicionado(a) com sucesso',
					payerDeleted: 'Pagador(a) removido(a) com sucesso',
					sampleCreated:
						'Amostra criada com sucesso | {count} amostras criadas com sucesso',
					sampleUpdated: 'Amostra atualizada com sucesso',
					sampleDeleted: 'Amostra removida com sucesso',
					examsDeleted: 'Exames removidos com sucesso',
				},

				removeExam: {
					title: 'Remover Exame',
					subtitle: 'Você tem certeza que deseja remover esse exame?',
					titleMultipleExams: 'Remover Exames',
					subtitleMultipleExams:
						'Você tem certeza que deseja remover todos os exames selecionados?',
				},

				removeExamGroup: {
					title: 'Remover Perfil',
					subtitle: 'Você tem certeza que deseja remover esse perfil?',
					titleMultipleExamGroups: 'Remover Perfis',
					subtitleMultipleExamGroups:
						'Você tem certeza que deseja remover todos os perfis selecionados?',
				},

				error: {
					cantDeleteSentExams: 'Você não pode deletar exames concluídos',
				},
			},

			tooltip: {
				copyRequest: 'Copiar Requisição',
				nextStatus: 'Próximo status',
			},
		},

		attachments: {
			name: 'Anexos',

			alert: {
				title: 'Remover Anexo',
				subtitle: 'Você tem certeza que deseja remover esse anexo?',
			},

			feedback: {
				success: {
					create: {
						attachment: 'Anexo adicionado com sucesso',
					},

					remove: {
						attachment: 'Anexo removido com sucesso',
					},
				},
			},

			tooltip: {
				removeAttachment: 'Remover anexo',
			},
		},

		insertResult: {
			name: 'Inserção de Resultado',

			signature: 'Assinatura',
			sendReport: 'Enviar Laudo',
			completeReport: 'Concluir Laudo',
			resendReport: 'Reenviar laudo',
			reportSent: 'Laudo enviado',
			tutor: 'Tutor',
			veterinarian: 'Veterinário(a)',
			patient: 'Paciente',
			clinic: 'Clínica',
			details: 'Detalhes',
			history: 'Histórico',
			patientSamples: 'Amostras do Paciente',
			viewExam: 'Visualizar exame',
			editExam: 'Editar exame',
			removeExam: 'Remover exame',
			cancelExam: 'Cancelar exame',
			sampleReports: 'Observação das amostras',

			signatureForm: {
				title: 'Adicionar assinatura',
				pathologist: 'Patologista',
				signature: 'Assinatura',
				saveSignature: 'Salvar assinatura',
				sign: 'Assinar',
			},

			sendReportForm: {
				title: 'Enviar Laudo',
				sendImmediately: 'Enviar Imediatamente',
				sendWithTimer: 'Enviar com Temporizador',
				sendUsingV1: 'Enviar laudo',
				sendUsingV2: 'Enviar usando a V2',
				sendAllReports: 'Enviar todos (BETA)',
			},

			tooltip: {
				timer: 'Tempo para o laudo ser enviado',
				pathologistSignature: 'Assinatura do Patologista',
				showVersionamentHistory: 'Histórico de Versões',
				useVersionEditable: 'Utilizar essa Versão do Exame',
				updateExamLayout: 'Redefinir layout',
				clearFields: 'Limpar campos',
			},

			alert: {
				titleSign: 'Remover Assinatura',
				subtitleSign: 'Você tem certeza que deseja remover a assinatura?',
				titleOldVersion: 'Versão Antiga',
				subtitleOldVersion: 'Você está entrando em uma versão antiga, deseja continuar?',
				titleCancelExam: 'Cancelar Exame',
				subtitleCancelExam:
					'Você tem certeza que deseja cancelar esse exame? Após isso você será redirecionado para a tela inicial',
			},

			examResultStatus: {
				new: 'Novo',
				newPrefix: 'Novo',
				underAnalysis: 'Em analise',
				underAnalysisPrefix: 'Em analise',
				typing: 'Em Digitação',
				typingPrefix: 'Digitação',
				toSign: 'Para assinar',
				toSignPrefix: 'Para assinar',
				completed: 'Completado',
				completedPrefix: 'Completado',
				canceled: 'Cancelado',
				canceledPrefix: 'Cancelado',
				waitingForAnalysis: 'Aguardando análise',
				waitingForAnalysisPrefix: 'Aguardando análise',
				awaitingAuthorization: 'Aguardando Autorização',
				awaitingAuthorizationPrefix: 'Aguardando Autorização',
				inAnalysis: 'Em Análise',
				inAnalysisPrefix: 'Em Análise',
				inValidation: 'Em validação',
				inValidationPrefix: 'Em validação',
				awaitingRelease: 'Aguardando Liberação',
				awaitingReleasePrefix: 'Aguardando Liberação',
				sent: 'Enviado',
				sentPrefix: 'Enviado',
				nonCompliance: 'Não Conformidade',
				nonCompliancePrefix: 'Não Conformidade',
				examType: 'Tipo de Exame',
				interfaced: 'Interfaceado',
			},

			errorsForm: {
				requestedExamNotFound: 'Exame solicitado não encontrado',
				passwordIncorrect: 'A senha está incorreta.',
				signedAlready: 'Este resultado já está assinado.',
				unknownError: 'Aconteceu um erro desconhecido.',
			},

			addon: {
				name: 'Adicional',
				addons: 'Adicionais',
				titleModal: 'Selecionar Adicionais',
				titleModalAddAddon: 'Adicionar Adicional',
				addManually: 'Cadastrar Adicional',
				editAddon: 'Editar Adicional',
				editMenuAddon: 'Editar',
				removeAddon: 'Remover',
				newAddon: 'Novo adicional',
				notFound: 'Nenhum adicional foi encontrado.',
				messageRemoveAddon: 'Após a ação de salvar, esse adicional será removido.',
				totalAddons: 'Total',

				alert: {
					title: 'Remover Adicional',
					subtitle: 'Você tem certeza que deseja remover esse adicional?',
				},

				tooltip: {
					addAddon: 'Incluir Adicionais',
					messageOtherPageSelected:
						'Cuidado! Possui addon(s) adicionado, que estão em outra(s) página(s).',
				},
			},

			feedback: {
				success: {
					create: {
						version: 'Uma nova versão foi criada com sucesso',
						reportSent: 'Laudo enviado com sucesso',
						signature: 'Assinatura adicionada com sucesso',
					},

					update: {
						version: 'Laudo atualizado com sucesso',
					},

					remove: {
						signature: 'Assinatura removido com sucesso',
					},
				},
			},
		},

		financial: {
			alert: {
				title: 'Remover Pagamento',
				subtitle: 'Você tem certeza que deseja remover o pagamento?',
			},

			tooltip: {
				editPayment: 'Editar Pagamento',
				removePayment: 'Remover Pagamento',
				printReceipt: 'Imprimir Comprovante',
			},

			table: {
				paying: 'Pagante',
				receipt: 'Comprovante',
				registeredBy: 'Registrado por',
				transaction: 'Transação',
				value: 'Valor',
			},

			typePaymentMethods: {
				other: 'Outros',
				bankingBillet: 'Boleto Bancário',
				paymentLink: 'Link de pagamento',
				instantPayment: 'PIX - Pagamento Instantâneo',
				pixCharge: 'Cobrança por PIX',
				fidelityProgram: 'Programa de Fidelidade',
				creditCard: 'Cartão de Crédito',
				debitCard: 'Cartão de Débito',
				withoutPayment: 'Sem Pagamento',
				bankingTransfer: 'Transferência Bancária',
				foodVoucher: 'Vale Alimentação',
				fuelVoucher: 'Vale Combustível',
				giftVoucher: 'Vale Presente',
				mealVoucher: 'Vale Refeição',
				digitalWallet: 'Carteira Digital',
				cashback: 'Cashback',
				check: 'Cheque',
				virtualCredit: 'Crédito Virtual',
				storeCredit: 'Crédito da Loja',
				cash: 'Dinheiro',
				bankingDeposit: 'Depósito Bancário',
			},

			feedback: {
				success: {
					create: {
						payment: 'Pagamento criado com sucesso',
					},

					update: {
						payment: 'Pagamento atualizado com sucesso',
					},

					remove: {
						payment: 'Pagamento removido com sucesso',
					},
				},
			},
		},

		invoices: {
			status: {
				pending: 'Pendente',
				paid: 'Paga',
				typing: 'Em digitação',
				awaitingPayment: 'Aguardando Pagamento',
				canceled: 'Cancelada',
			},
		},

		previousResults: {
			table: {
				notFound: 'Nenhum resultado encontrado',
			},
		},

		orders: {
			name: 'Pedidos',
			order: 'Pedido',
			createdBy: 'Criado por',
			copyOrderLink: 'Copiar link do pedido',
			viewOrder: 'Visualizar pedido',
			editOrder: 'Editar pedido',
			removeOrder: 'Excluir pedido',
			cancelOrder: 'Cancelar pedido',
			newOrder: 'Novo Pedido',
			notFound: 'Nenhum registro de pedido encontrado',

			newOrderForm: {
				title: 'Novo pedido',
			},

			form: {
				cancelOrder: 'Cancelar pedido',
			},

			table: {
				search: 'Buscar pedidos',
			},

			status: {
				result: 'Resultado',
				draft: 'Em rascunho',
				draftPrefix: 'Rascunho',
				new: 'Novo',
				newPrefix: 'Novo',
				inLogistics: 'Em logística',
				inLogisticsPrefix: 'Logística',
				processing: 'Processando',
				processingPrefix: 'Processando',
				delivered: 'Entregue',
				deliveredPrefix: 'Entregue',
				billed: 'Faturado',
				billedPrefix: 'Faturado',
				finished: 'Finalizado',
				finishedPrefix: 'Finalizado',
				canceled: 'Cancelado',
				canceledPrefix: 'Cancelado',
				budget: 'Orçamento',
				awaitingSample: 'Aguardando Amostra',
				awaitingPayment: 'Aguardando Pagamento',
				received: 'Recebido',
				awaitingRelease: 'Aguardando Liberação',
			},

			feedback: {
				success: {
					create: {
						order: 'Pedido criado com sucesso',
					},

					update: {
						order: 'Pedido atualizado com sucesso',
					},

					remove: {
						order: 'Pedido removido com sucesso',
					},

					cancel: {
						order: 'Pedido cancelado com sucesso',
					},
				},
			},
		},

		navigationDrawer: {
			search: 'Pesquisar',
			orderBy: 'Ordenar por',
			sortOrder: 'Ordem de classificação',
			filterByDate: 'Filtrar por data',
			examPriority: 'Prioridade do exame',
			examCategory: 'Categoria do exame',
			examStatus: 'Status do exame',
			filterByBilling: 'Filtrar por faturamento',

			sortBy: {
				status: 'Status',
				priority: 'Prioridade',
				price: 'Valor',
				discount: 'Desconto',
				dueDate: 'Prazo',
				createdDate: 'Data de criação',
				entranceDate: 'Data de entrada',
				lastUpdate: 'Última atualização',
				ascending: 'Ascendente',
				descending: 'Decrescente',
				yes: 'Sim',
				no: 'Não',
				protocolNumber: 'Número de protocolo',
				issuedAt: 'Emitido em',
			},

			request: {
				filterRequests: 'Filtrar Requisições',
				canSearchBy1: 'Você pode pesquisar por: número curto de protocolo,',
				canSearchBy2: 'número de protocolo, nome do cliente, veterinário ou clínica.',
				requestPriority: 'Prioridade da requisição',
				requestStatus: 'Status da requisição',
			},

			exam: {
				filterExams: 'Filtrar exames',
			},

			bill: {
				filterBills: 'Filtar faturamentos',
			},

			order: {
				filterOrder: 'Filtrar pedidos',
				orderStatus: 'Status do pedido',
				orderPriority: 'Prioridade do pedido',
			},
		},

		animals: {
			name: 'Pacientes',

			searchPlaceholder: 'Buscar Pacientes',

			alive: 'Vivo',
			dead: 'Morto',
			castrated: 'Castrado',
			doctor: 'Médico',
			responsableExist: 'Existem',
			descriptionDoctorResponsable: 'médicos responsável pelo paciente',
			descriptionTutorResponsable: 'tutores responsável pelo paciente',
			notFoundTutors: 'Não possui tutor',
			notFoundDoctors: 'Não possui Médico',

			table: {
				headers: {
					animalTutor: 'Paciente / Tutor',
					animal: 'Paciente',
					raceSpecie: 'Raça / Espécie',
					sex: 'Sexo',
					age: 'Idade',
					status: 'Status',
				},

				notFound: 'Nenhum registro de paciente encontrado.',
			},

			header: {
				backToList: 'Voltar para lista de pacientes',
				tutor: 'Tutor',
				editAnimal: 'Editar paciente',
			},

			form: {
				title: 'Novo Paciente',
				editAnimal: 'Editar Paciente',
				generateNewAUID: 'Gerar Novo AUID',
				uniqueIdentification: 'Identificação Única do Paciente',
				animalName: 'Nome do Paciente',
				birthDate: 'Data de Nascimento',
				year: 'Anos (Calcular Data)',
				days: 'Dias (Calcular Data)',
				yearNotFound: 'Nenhum Ano encontrado.',
				month: 'Meses (Calcular Data)',
				monthNotFound: 'Nenhum Mês encontrado.',
				specie: 'Espécie',
				raceNotFound: 'Nenhuma raça encontrada.',
				sex: 'Sexo',
				coat: 'Pelagem',
				fertility: 'Fertilidade',
				weight: 'Peso',
				microchip: 'Microchip',
				animalRegistration: 'Registro do Paciente',
				observations: 'Observações',
				tutorOrDoctor: 'Tutor ou Médico Veterinário',
				selectRegisterAnimal: 'Selecione ou cadastre os responáveis pelo paciente',
				searchTutorExisting: 'Busque por um',
				searchTutorExisting2: 'já existente ou cadastre um novo.',
				selectTutorClient: 'Selecionar um Tutor/Cliente',
				doctor: 'médico veterinário',
				selectDoctorClient: 'Selecionar um Médico Veterinário',
				addAnimal: 'Adicionar Paciente',
				addPatient: 'Incluir Paciente',
				note1: 'Procure cadastrar o máximo de informações possíveis do paciente!',
				note2:
					'Quanto mais precisas forem as informações inseridas, mais\n' +
					'\t\t\t\t\t\t\t\t\tinteligente a plataforma se tornará.',
				import: 'Importar',
			},

			bottomSheet: {
				allCustomerFound: 'Total de Clientes Encontrados',
				allCustomer: 'Total de Clientes',
				searchPlaceholder: 'Buscar por Nome, Cpf, Telefone,E-mail...',
				doctor: 'Médico Veterinário',
				clinic: 'Clínica',
				doctorSimple: 'médico',
				tutor: 'Tutor',

				alert: {
					existCustomerType: 'Existem Clientes do tipo',
					to: ', para',
					viewCustomer: 'visualizar o Cliente',
					hereYouShould: 'aqui você deverá',
					updateRegister: 'Atualizar o Cadastro',
				},

				selectClientType: 'Selecionar tipo do cliente',
				titleNotFound: 'Não encontramos nenhum resultado para a sua busca',
				descriptionNotFound:
					'Tente refazer sua busca utilizando mais caracteres ou crie um novo cadastro utilizando o\n' +
					'\t\t\tbotão abaixo.',
				selectTutorDoctor: 'Selecionar',
				chooseAnimal: 'Escolha um paciente',
				selectAnimal: 'Selecionar paciente',
				hideInfoAnimal: 'Ocultar pacientes do(a)',
				viewInfoAnimal: 'Visualizar pacientes do(a)',
				hideInfoContact: 'Ocultar informações do contato',
				viewInfoContact: 'Visualizar informações do contato',
				telephone: 'Telefone',
				textRegister1: 'Você poderá cadastrar',
				textRegister2: 'um',
				textRegister3: 'Novo',
				register: 'Cadastrar',
				alreadyRegisterAnimal:
					'Você já está cadastrando um paciente por favor selecione o tutor ou médico',
				clientType: 'Tipo de cliente',
				client: 'Cliente',
				animal: 'Paciente',
				simpleAnimal: 'paciente',
			},

			tooltip: {
				animalDetails: 'Detalhes do Paciente',
				removeAnimal: 'Remover Paciente',
			},

			details: {
				breed: 'Raça',
				specie: 'Espécie',
				size: 'Porte',
				tutor: 'Tutor(a)',
				coat: 'Pelagem',
				noCoat: 'Sem pelagem',
				sex: 'Sexo',
				age: 'Idade',
				death: {
					name: 'Óbito',
					yes: 'Sim',
					no: 'Não',
					uninformed: 'Não informado',
				},
				male: 'Macho',
				female: 'Fêmea',
				noSex: 'Indefinido',
				day: 'dia',
				days: 'dias',
				month: 'mes',
				months: 'meses',
				year: 'ano',
				years: 'anos',
				categories: {
					history: 'Histórico',
					weights: 'Pesos',
					appointments: 'Consultas',
					vaccines: 'Vacinas',
					prescriptions: 'Receitas',
					exams: 'Exames',
					medicines: 'Medicamentos',
					documents: 'Documentos',
					observation: 'Observação',
				},
			},

			customer: {
				doesntHave: 'Este cliente ainda não possui paciente! =D',
				note: 'Para que um paciente seja registrado basta adicionar um paciente com ',
				note2: 'o nome deste cliente que o paciente será computado.',
				startRegister: 'Iniciar cadastro do paciente',
			},

			feedback: {
				success: {
					create: {
						animal: 'Paciente criado com sucesso',
					},

					update: {
						animal: 'Paciente atualizado com sucesso',
					},

					remove: {
						animal: 'Paciente removido com sucesso',
					},
				},
			},
		},

		customers: {
			name: 'Clientes',
			addCustomer: 'Adicionar Cliente',
			bin: 'Lixeira',
			costumerList: 'Lista de clientes',
			import: 'Importar',

			table: {
				headers: {
					nameSocialReason: 'Nome / Razão Social',
					phone: 'Telefone',
				},
				notFound: 'Nenhum registro de clientes encontrado.',
				noPhone: 'Sem telefone',
				verified: 'Verificado',
				pending: 'Pendente',
			},

			header: {
				averageTicket: 'Ticket médio',
				accomplishedServices: 'Serviços realizados',
				schedules: 'Agendamentos',
			},

			placeholders: {
				selectCustomerType: 'Selecione o tipo de cliente',
			},

			customerDetails: {
				customerData: 'Dados de cadastro',
				phones: 'Telefone(s)',
				profile: 'Perfil',
				crmv: 'CRMV',
				address: 'Endereço',
				noPhone: 'Não possui telefone',
				noAddress: 'Sem endereço',
				noNeighborhood: 'Sem bairro',
				noComplement: 'Sem complemento',
				noCPFCNPJ: 'Sem CPF/CNPJ',
				noCity: 'Sem cidade',
				noZipCode: 'Sem CEP',
			},

			history: {
				doesntHave: 'Este Cliente ainda não possui histórico! =D',
				note: 'Para que um histórico seja registrado basta processar algum serviço ou produto em nome deste',
				note2: 'cliente que o histórico será computado.',
				startRequest: 'Iniciar uma requisição',
				createSchedule: 'Criar um agendamento',
				importHistory: 'Importar histórico de outro sistema',
			},

			exams: {
				doesntHave: 'Este Cliente ainda não possui exame! =D',
				note: 'Para que um exame seja registrado basta cadastrar um exame',
				note2: 'com este cliente que será computado.',
				registerExam: 'Iniciar Cadastro do Exame',
				importHistory: 'Importar exames de outro sistema',
			},

			agreements: {
				doesntHave: 'Este Cliente ainda não possui convênio! =D',
				note: 'Para registrar o cliente em um convênio, basta criar um novo convênio',
				note2: 'ou adiciona-lo em um já existente.',
				registerAgreement: 'Criar um novo convênio',
			},

			searchCustomers: 'Adicione filtros pressionando "ENTER"',

			form: {
				addCustomer: 'Adicionar Cliente',
				editCustomer: 'Editar Cliente',
				customerProfile: 'Perfil do Cliente',
				registrationType: 'Tipo de Cadastro',
				physicalPerson: 'Pessoa Física',
				legalPerson: 'Pessoa Jurídica',
				maskActive: 'Mascara ativada',
				maskDesactive: 'Mascara desativada',
				maskActiveText:
					'Desativar Mascara Automática (Caso a mascara não seja do formato do seu RG)',
				maskDesactiveText: 'Ativar Mascara Automática (Existe dois tipos de formato)',
				issuingAgency: 'Orgão Emissor',
				fullName: 'Nome Completo',
				state: 'Estado (UF)',
				mainEmail: 'Email principal',
				emailCollections: 'Email para Cobranças',
				businessPhone: 'Telefone Comercial',
				zipCodeNotFound: 'Não encontramos seu endereço pelo CEP!',
				messageFirstCpf: 'Por favor, digite o CPF primeiro.',
				requesterCRMV: 'CRMV do Requisitante',
				municipalRegistration: 'Inscrição Municipal',
				fantasyName: 'Nome Fantasia',
				socialReason: 'Razão Social',
				requester: 'Requisitante',
				emailRequester: 'E-mail do Requisitante',
			},

			issuingNotFound: 'Orgão não encontrado.',
			stateNotFound: 'Estado não encontrado.',
			placeholderIssuing: 'Selecione o Orgão Emissor',

			filters: {
				tutor: 'Tutor',
				doctor: 'Médico Veterinário',
				clinic: 'Clínica',
				laboratory: 'Laboratório',
				uninformed: 'Não Informado',
				placeholder: 'Tipo de Cadastro',
			},

			tooltip: {
				viewProfile: 'Visualizar perfil',
				removeCustomer: 'Remover cliente',
				restoreCustomer: 'Restaurar cliente',
				profile: 'Perfil',
			},

			alert: {
				title: 'Remover Cliente',
				subtitle: 'Você tem certeza que deseja remover esse cliente?',
			},

			startCustomers: {
				title: 'Oooops! Ainda não possuem registros de Clientes =D',
				subtitle: 'Para que uma requisição seja processada é necessários que exista um',
				subtitle2: 'cadastro de\n' + '\t\t\t\tcliente para vincular à requisição.',
				registerNewCustomers: 'Cadastrar Novo Cliente',
				importCustomers: 'lmportar clientes de outro sistema',
			},

			customerView: {
				backToList: 'Voltar para a lista de clientes',
				categories: {
					historic: 'Histórico',
					bonds: 'Vínculos',
					animals: 'Animais',
					schedules: 'Agendamentos',
					requestedExams: 'Exames solicitados',
					purchases: 'Compras',
					documents: 'Documentos',
					examReport: 'Laudo',
					agreement: 'Convênio',
				},
			},

			feedback: {
				success: {
					create: {
						client: 'Cliente criado com sucesso',
						importClient: 'Clientes importados com sucesso',
					},

					update: {
						client: 'Cliente atualizado com sucesso',
					},

					remove: {
						client: 'Cliente removido com sucesso',
						importHistory: 'Histórico de importação removido com sucesso',
						restore: 'Cliente restaurado com sucesso',
					},
				},
			},
		},

		apiKey: {
			name: 'Gerenciar Chaves de APIs',
			apiKeyAbbreviation: 'API',

			addKey: 'Adicionar Chave de API',

			table: {
				keyName: 'Chaves de API',
				tokenPrefix: 'Prefixo do token',
				createdBy: 'Criado(a) por',
				updatedOn: 'Atualizado(a) em',
				addedIn: 'Adicionado(a) em',
				status: 'Status',
			},

			form: {
				addKey: 'Adicionar chave de api',
				title: 'Chave de API criada',
				subtitle: `Copie a chave e utilize-a com o parâmentro “api_key” no cabeçalho de suas requisições`,
				accessKey: 'Chave de acesso',
			},

			alert: {
				title: 'Remover Chave de API',
				subtitle: 'Você tem certeza que deseja remover a chave de api?',
			},

			tooltip: {
				editApikey: 'Editar Chave de API',
				removeApikey: 'Remover Chave de API',
			},

			feedback: {
				successes: {
					create: {
						apikey: 'Chave de API criado com sucesso',
					},

					update: {
						apikey: 'Chave de API atualizado com sucesso',
						status: 'Status atualizado com sucesso',
					},

					remove: {
						apikey: 'Chave de API removido com sucesso',
					},
				},
			},
		},

		recipients: {
			name: 'Recipientes',

			addRecipient: 'Adicionar Recipiente',

			descriptionPlan: 'Gerencie os recipientes que serão usados no cadastro de Exames.',
			knowMore: 'Saiba mais',
			searchPlaceholder: 'Buscar recipientes',

			table: {
				notFound: 'Nenhum registro de recipientes encontrado.',
				recipient: 'Recipiente',
				subtitle: 'Legenda',
			},

			form: {
				addRecipient: 'Adicionar Recipiente',
				editRecipient: 'Editar Recipiente',
				descriptionSample: 'Descrição da Amostra',
				subtitle: 'Legenda',
				icon: 'Ícone',
				color: 'Cor',
			},

			icons: {
				pipe: 'Tubo',
				blade: 'Lamina',
				bowl: 'Pote',
				swab: 'Swab',
				drop: 'Gota',
				soda: 'Soda',
				dropper: 'Conta-gotas',
				bullet: 'Bullet',
				flask: 'Frasco',
				flaskRound: 'Frasco redondo',
				cylinder: 'Cilindro',
				nail: 'Prego',
				oar: 'Remo',
				rivet: 'Rebite',
				syringe: 'Seringa',
			},

			alert: {
				title: 'Remover Recipiente',
				subtitle: 'Você tem certeza que deseja remover esse recipiente?',
				want: 'Deseja',
				deleteRecipient: 'excluir este recipiente?',
				ifDeleteRecipient: 'Caso exclua este recipiente',
				requestExam: 'requisição de exames',
				warningDescription:
					'todos os exames que contém este recipiente vinculado, perderão poderão causar\n' +
					'\t\t\t\tproblemas na',
				recomendation:
					'Recomendamos que você crie um novo recipiente e faça as alterações nos exames\n' +
					'\t\t\t\t\tque usam este recipiente, para que não tenha problemas.',
			},

			tooltip: {
				editRecipient: 'Editar recipiente',
				removeRecipient: 'Remover recipiente',
			},

			feedback: {
				successes: {
					create: {
						recipient: 'Recipiente criado com sucesso',
					},

					update: {
						recipient: 'Recipiente atualizado com sucesso',
					},

					remove: {
						recipient: 'Recipiente removido com sucesso',
					},
				},
			},
		},

		tags: {
			name: 'Rótulos',
			descriptionPlan: 'Gerencie os rótulos que serão usados no cadastro de Exames.',
			searchPlaceholder: 'Buscar rótulos',
			addTag: 'Adicionar Rótulo',

			table: {
				tag: 'Rótulo',
				notFound: 'Nenhum registro de rótulos encontrado.',
			},

			form: {
				addTag: 'Adicionar Rótulo',
				editTag: 'Editar Rótulo',
				tagName: 'Nome do Rótulo',
				preview: 'Pré Visualização',
				description: 'Descrição (Opcional)',
			},

			tooltip: {
				editTag: 'Editar rótulo',
				removeTag: 'Remover rótulo',
			},

			alert: {
				title: 'Remover Rótulo',
				subtitle: 'Você tem certeza que deseja remover esse rótulo?',
			},

			feedback: {
				success: {
					create: {
						tag: 'Rótulo criado com sucesso',
					},

					update: {
						tag: 'Rótulo atualizado com sucesso',
					},

					remove: {
						tag: 'Rótulo removido com sucesso',
					},
				},
			},
		},

		methods: {
			name: 'Métodos',

			addMethod: 'Adicionar Método',

			tooltip: {
				editMethod: 'Editar método',
				removeMethod: 'Remover método',
			},

			alert: {
				title: 'Remover Método',
				subtitle: 'Você tem certeza que deseja remover esse método?',
			},

			feedback: {
				success: {
					create: {
						method: 'Método criado com sucesso',
					},

					update: {
						method: 'Método atualizado com sucesso',
					},

					remove: {
						method: 'Método removido com sucesso',
					},
				},
			},
		},

		equipments: {
			name: 'Equipamentos',
			equipmentsAbbreviation: 'Equip.',

			equipment: 'equipamento',

			form: {
				addEquipment: 'Adicionar Equipamento',
				editEquipment: 'Editar Equipamento',
				equipmentName: 'Nome do equipamento',
			},

			table: {
				equipment: 'Equipamento',
			},

			alert: {
				title: 'Remover Equipamento',
				subtitle: 'Você tem certeza que deseja remover esse equipamento?',
			},

			tooltip: {
				editEquipment: 'Editar equipamento',
				removeEquipment: 'Remover equipamento',
			},

			status: {
				newInterfacing: 'Novo interfaceamento',
				inHomologation: 'Em homologação',
				homologated: 'Homologado',
				waitingForApproval: 'Aguardando aprovação',
				interfaced: 'Interfaceado',
			},

			feedback: {
				success: {
					create: {
						equipment: 'Equipamento criado com sucesso',
					},

					update: {
						equipment: 'Equipamento atualizado com sucesso',
						bound: 'Equipamento vínculado com sucesso',
					},

					remove: {
						equipment: 'Equipamento removido com sucesso',
					},
				},
			},
		},

		attributes: {
			name: 'Permissões',

			table: {
				searchLabel: 'Buscar por permissões',
				createNewAttribute: 'Criar nova permissão',
				noDataFound: 'Nenhum atributo encontrado.',
				noMembersFound: 'Nenhum membro encontrado.',
			},

			addMembers: 'Adicionar membros',
			editPermissions: 'Editar permissões',
			addResource: 'Adicionar recurso',
			addAllResources: 'Adicionar todos',
			resourcesAndPermissions: 'Recursos & Permissões',
			newAttribute: 'Nova permissão',
			updateAttribute: 'Atualizar atributo',
			notFound: 'Nenhum atributo encontrando',
			sector: 'Setor ou Departamento',
			functionSector: 'Função ou Cargo',

			alert: {
				titleSelectGroupAttribute: 'Adicionar Grupo/Atributo',
				subtitleSelectGroupAttribute: 'Selecionar Grupo ou Atributo',
				options: {
					group: 'Grupo',
					attribute: 'Atributo',
				},
				titleAddGroup: 'Adicionar Grupo',
				titleEditGroup: 'Editar Grupo',
				subtitleAddGroup: 'Nome do grupo',
				titleAddAttribute: 'Adicionar atributo',
				subtitleAddAttribute: 'Nome do atributo',
				titleRemoveAttribute: 'Remover Atributo',
				titleEditAttribute: 'Editar atributo',
				subtitleRemoveAttribute: 'Você tem certeza que deseja remover esse atributo?',
				subtitleRemoveAttributeFormula:
					'Se você excluir esse atributo, todas as fórmulas vinculadas a ele também serão excluídas.',
				attributeTypes: {
					title: 'Tipo',
					simpleText: 'Texto Simples',
					textarea: 'Texto Longo',
					integer: 'Inteiro',
					decimal: 'Decimal',
					formula: 'Fórmula',
					date: 'Data',
					positiveNegative: 'Positivo/Negativo',
					image: 'Imagem',
					select: 'Multipla Escolha',
					method: 'Método',
					percentage: 'Porcentagem',
				},
				unit: 'Unidade',
				selectOption: 'Opções',
				percentage: {
					line1: 'O atributo do tipo porcentagem só deve ser usado caso ele for baseado em outro atributo do tipo inteiro ou decimal,',
					line2: 'a soma de todos os atributos do tipo porcentagem vinculados ao mesmo atributo deve dar o total de 100%.',
				},
				percentageWarning:
					'O atributo porcentagem é exclusivo do exame de Hemograma. Para utilizar valores percentuais comuns, altere para o tipo inteiro ou decimal.',
			},

			formula: {
				title: 'Informações do atributo',
				attributeName: 'Nome do atributo',
				type: 'Tipo',
				unit: 'Unidade',
				specie: 'Espécie (Opcional)',
				specieNotFound: 'Espécie não encontrada',
				attributeFormula: 'Fórmula do Atributo',
				createFormulaText: 'Crie sua fórmula selecionando os atributos e operações',
				note: 'Após selecionar a fórmula desejada, você deverá vincular os atributos do exame à fórmula para obtenção do resultado desejado.',
				learnMore: 'Saiba mais',
				addOtherFormula: 'Adicionar outra Fórmula',
				save: 'Salvar',
				formula: 'Fórmula',
				specieSelected: 'Espécie',
				youAreEditing: 'Você está editando esta fórmula',
				formulaName: 'Nome da Fórmula',
				selectAttribute: 'Selecione o atributo',
			},

			dontShowTitle: 'Não mostrar título',

			tooltip: {
				removeGroup: 'Remover grupo',
				editGroup: 'Editar grupo',
				disableGroup: 'Desativar grupo',
				removeAttribute: 'Remover atributo',
				editAttribute: 'Editar atributo',
				addAttribute: 'Adicionar atributo',
			},

			feedback: {
				success: {
					create: {
						attribute: 'Atributo criado com sucesso',
						memberAttribute: 'Atributo adicionado ao membro com sucesso',
					},

					updated: {
						attribute: 'Atributo atualizado com sucesso',
					},

					remove: {
						attribute:
							'Atributo removido com sucesso | Atributos removidos com sucesso',
						memberAttribute: 'Atributo removido do membro com sucesso',
					},
				},
			},
		},

		users: {
			table: {
				searchLabel: 'Buscar membros',
				noDataFound: 'Nenhum Convite/Pessoa encontrado.',
				filterAttribute: 'Filtro por Atributos',
				header: {
					attributes: 'Atributos',
					policies: 'Políticas',
					invitedBy: 'Convidado Por',
					guestIn: 'Convidado',
					lastActivity: 'Última Atividade',
				},
				buttonInviteUser: 'Convidar Usuário',
				notFoundInvite: 'Nenhum registro de convites encontrado.',
				notFoundMember: 'Nenhum registro de membros encontrado.',
			},

			alert: {
				kickMember: 'Expulsar Membro',
				textKickMember: 'Você tem certeza que deseja expulsar esse membro da organização?',
			},

			tooltip: {
				disableStatus: 'Desativar status',
				activeStatus: 'Ativar status',
				activePathologist: 'Ativar Patologista',
				disablePathologist: 'Desativar Patologista',
				pathologistNotCrmv:
					'O usuário não possui informações do registro do conselho de medicina para habilitá-lo como patologista',
				invalidEmail: 'Email inválido!',
				removeInvite: 'Remover Convite',
				removeInvites: 'Remover Convite(s)',
				copyLinkInvite: 'Copiar link do convite',
				kickUser: 'Expulsar membro',
				kickUsers: 'Expulsar membro(s)',
			},

			form: {
				emailAndUser: 'Informe o e-mail ou o nome do usuário',
				inviteLink: 'Link de Convite',
				attributes: 'Atributos',
				policies: 'Políticas',
				notifyPerson: 'Notificar os convidados',
				notifyDescription: 'Enviar uma notificação para os e-mail selecionados.',
				personalizedMessage: 'Mensagem personalizada',
				membersAlreadyInvitations: 'Usuários já convidados não aparecerão na seleção.',
			},
		},
		forms: {
			table: {
				searchLabel: 'Buscar por formulários...',
				noDataFound: 'Nenhum registro de formulário encontrado.',
			},
			templates: {
				startANewForm: 'Iniciar um novo formulário',
				modelGallery: 'Galeria de modelos',
				inBlank: 'Em branco',
				defaultTemplate: 'Template padrão {n}',
			},
			questionTypes: {
				shortAnswer: 'Reposta curta',
				paragraph: 'Parágrafo',
				multipleChoice: 'Múltipla escolha',
				draw: 'Desenhar',
				checkBoxes: 'Caixas de seleção',
				fileUpload: 'Upload de arquivo',
			},
			textPlaceholders: {
				paragraph: 'Texto resposta longa',
				text: 'Texto resposta curta',
				upload: 'Adicionar arquivo',
				date: 'Mês, dia, ano',
				time: 'Horário',
			},

			tooltip: {
				editForm: 'Editar formulário',
				removeForm: 'Remover formulário',
			},

			alert: {
				title: 'Remover Formulário',
				subtitle: 'Você tem certeza que deseja remover esse formulário?',
			},

			addOption: 'Adicionar opção',
			addOther: 'Adicionar "outro"',
			addQuestion: 'Adicionar pergunta',
			addSection: 'Adicionar seção',
			addTitleAndDescription: 'Adicionar título e descrição',
			or: 'ou',
			untitled: 'Sem título',
			untitledForm: 'Formulário sem título',
			untitledSection: 'Seção sem título',
			withoutDescription: 'Sem descrição',
			formWithoutDescription: 'Formulário sem descrição',
			sectionWithoutDescription: 'Seção sem descrição',
			preview: 'Visualizar',
			description: 'Descrição (opcional)',
			questions: 'Perguntas',
			question: 'Pergunta',
			answers: 'Respostas',
			section: 'Seção {current} de {total}',
			option: 'Opção {n}',
		},

		account: {
			profile: 'Perfil',
			nickname: 'Apelido',
			cpf: 'CPF',
			gender: 'Gênero',
			language: 'Idioma',
			personalData: 'Informações Pessoais',
			personalAboutYou: 'Por favor, forneça informações básicas sobre você.',
			additionalData: 'Informações Complementares',
			aboutYou: 'Compartilhe mais detalhes sobre você e suas atividades diárias.',
			defaultOrganization: 'Organização Padrão',
			defaultOrganizationAbout:
				'Organização que será selecionada por padrão ao acessar a plataforma.',
			selectOrganization: 'Selecione uma organização (Opcional)',
			occupation: 'Qual sua ocupação?',
			interestArea: 'Áreas de interesse',
			council: 'Conselho',
			register: 'Registro',
			state: 'Estado',
			mapa: 'Registro no MAPA',
			noOccupation: 'Nenhuma ocupação encontrada',
			photoChanged: 'Foto alterado com sucesso!',
			noInterest: 'Nenhum Interesse Encontrado',
			beCalled: 'Como gostaria de ser chamado?',
			whatEmail: 'Qual seu endereço de email?',
			genres: {
				choose: 'Selecionar',
				male: 'Masculino',
				female: 'Feminino',
				other: 'Prefiro não informar',
			},
			interests: {
				medical: 'Clinica médica',
				acupuncture: 'Acupuntura',
				anesthesia: 'Anestesia',
				cattleRaising: 'Bovinocultura',
				cardiology: 'Cardiologia',
				surgicalClinic: 'Clínica Cirúrgica',
				behavioral: 'Comportamental',
				dermatology: 'Dermatalogia',
				edocrinology: 'Endocrinologia',
				physiotherapy: 'Fisioterapia',
				homeopathy: 'Homeopatia',
				immunology: 'Imunologia',
				nephrology: 'Nefrologia',
				neurology: 'Neurologia',
				diagnosticMedicine: 'Medicina Diagnóstica',
			},
			occupations: {
				veterinarian: 'Médico Veterinário',
				vetStudent: 'Estudante de Medicina Veterinária',
				cattleman: 'Pecuarista',
				distributor: 'Lojista / Distribuidor',
				tutor: 'Tutor',
			},
			phone: {
				title: 'Meus números de telefone',
				subtitle: 'Veja e faça a gestão de todos os telefones associados à sua conta.',
				addNewPhone: 'Adicionar número de telefone',
				alert: {
					title: 'Adicionar número de telefone',
					subtitle: 'Enviaremos um SMS para o seu número de telefone',
					user: 'Usuário',
					organization: 'Organização',
					phoneOrCell: 'Número do telefone ou celular',
					smsSent: 'SMS enviado para',
					validateSms: 'Validar SMS',
					resendSms: 'Reenviar SMS',
					validate: 'Validate',
					successPhone: 'O telefone foi cadastrado com sucesso!',
					successCell: 'O celular foi cadastrado com sucesso!',
					deleteMessage: {
						want: 'Você deseja',
						delete: 'deletar',
						thisPhone: 'este telefone?',
						thisCell: 'este celular?',
						this: 'Está',
						deletion: 'exclusão',
						willBe: 'será',
						irreversible: 'irreversível',
						pleaseMakeSure: 'por favor tenha certeza que deseja remover.',
						removedPhone: 'O telefone foi removido com sucesso.',
						removedCell: 'O celular foi removido com sucesso.',
					},
					yesDelete: 'Sim, excluir!',
					noDelete: 'Não',
				},
				phone: 'Telefone',
				cell: 'Celular',

				alertRemove: {
					title: 'Remover Telefone',
					subtitle: 'Você tem certeza que deseja remover esse telefone?',
				},

				feedback: {
					success: {
						create: {
							phone: 'O telefone foi adicionado com sucesso.',
						},

						update: {
							phone: 'O telefone foi atualizado com sucesso.',
						},

						remove: {
							phone: 'O telefone foi removido com sucesso.',
						},
					},
				},
			},
			address: {
				myAddresses: 'Meus endereços',
				subtitleMyAddresses:
					'Veja e faça a gestão de todos os endereços associados à sua conta.',
				addAddress: 'Adicionar endereço',
			},

			feedback: {
				success: {
					create: {},

					update: {
						profile: 'O Perfil foi atualizado com sucesso.',
					},

					remove: {},
				},
			},

			main: 'Principal',
		},

		organizations: {
			name: 'Organizações',
			description: 'Veja e faça a gestão de todas as informações da sua organização.',

			errorInter: 'Ocorreu um erro interno',
			notFound: 'Você não pertence a nenhuma organização.',

			addOrganization: 'Adicionar Organização',
			loading: 'Carregando...',

			tooltip: {
				editOrganization: 'Editar Organização',
				removeOrganization: 'Remover Organização',
				transferOwnership: 'Transferir propriedade',
				exitOrganization: 'Sair da Organização',
			},

			form: {
				addOrganization: 'Adicionar Organização',
				membersOrganization: 'Membros da organizacão',
			},

			alert: {
				title: 'Remover Organização',
				subtitle: 'Você tem certeza que deseja remover essa organização?',
			},

			cpfOwner: 'CPF do proprietário',
			ownerName: 'Nome do proprietário',
			socialReason: 'Razão Social',
			tradeName: 'Nome Fantasia',
			personType: 'Tipo Pessoa',
			organizationType: 'Tipo da organização',
			domain: 'Domínio',
			domainInfo:
				'Este domínio é único e será usado para os seus clientes acessarem sua Área do cliente',
			imageSize:
				'A imagem deve conter no {maxSize}. (*.PNG). Evite deixar algum espaçamento branco nas laterais da imagem e utilize o logotipo com fundo transparente.',
			maxImageSize: 'máximo 200x70 px',
			nsfeWarning: 'Sua "empresa" estará impossibilitada de emitir NFS-e.',
			onePhysicalOrganization:
				'Você pode ser proprietário somente de 1 organização (pessoa física)',

			feedback: {
				success: {
					create: {
						organization: 'A organização foi criada com sucesso.',
					},

					update: {
						organization: 'A organização foi atualizada com sucesso.',
					},

					patch: {
						organization: 'Você transferiu a organização com sucesso.',
					},

					remove: {
						organization: 'A organização foi removida com sucesso.',
						getOutOrganization: 'Você saiu da organização com sucesso.',
					},
				},
			},
			referrer: 'Indicado por (Opcional)',
		},

		importations: {
			name: 'Importar / Exportar',
			title: 'Importe facilmente dados de outros sistemas para a ClusterVet',
			import: 'Importar',
			customers: 'clientes',
			descriptionImport: 'Importe sua lista de empresas/clientes',
			productServices: 'produtos e serviços',
			descriptionProductServices: 'Importe seus exames e configuração de layout',
			historyImportation: 'Histórico de importação',

			table: {
				headers: {
					file: 'Arquivo',
					module: 'Módulo',
					registers: 'Registros',
					imported: 'Importados',
					invalids: 'Inválidos',
				},

				notFound: 'Nenhum registro de importação encontrado.',
			},

			form: {
				title: 'Importar clientes',
				selectFileCsvTsv: 'Selecione o arquivo (CSV ou TSV) e importe para a ClusterVet.',
				selectFileText: 'Selecionar Arquivo',
				hintText: 'Formatos permitidos: CSV ou TSV',
				ifYouPrefer: 'Se preferir,',
				downloadText: 'faça o download da nossa planilha modelo',
				compartionText:
					'e compare com o arquivo de importação para garantir que o seu arquivo\n' +
					'\t\t\t\t\t\t\testeja de acordo com os padrões.',
				importQuestions: 'Dúvidas na importação?',
				mapFields: 'Mapear campos',
				checkColumnText:
					'Verifique se as colunas do arquivo importado batem com as colunas a importar\n' +
					'\t\t\t\t\t\tpara a ClusterVet.',
				fieldsClusterVet: 'Campos da ClusterVet',
				columnCsv: 'Colunas do CSV',
				required: 'obrigatório',
				selectColumn: 'Selecione a coluna',
				changeFile: 'Alterar arquivo',
				importing: 'Importando',
				import: 'Importar',
				importInProgress: 'Importação em progresso',
				importInProgressDescription:
					'Estamos processando sua importação, em instantes você poderá ver os dados\n' +
					'\t\t\t\t\t\timportados na sua lista de',
				seeDetails: 'Ver detalhes',
				mappedFields: 'Campos mapeados',
				totalColumnFile: 'Total de colunas no arquivo',
				columnSelected: 'Colunas selecionados',
				registers: 'Registros',
				fileLines: 'Linhas do arquivo',
				totalValidImport: 'Total válido(s) para importação',
				lineErrorImport: 'Linhas com erro(s)',
				downloadCsv: 'Baixar CSV atualizado',
				detailsName: 'Detalhes da Importação',
				searchPlaceholderDetails: 'Buscar por Nome, E-mail, CNPJ, CPF.',
				filterBySituation: 'Filtro por situacão',
				filterSelecteds: 'Filtro Selecionados',
				filters: {
					nomeInvalid: 'nome inválido',
					cpfInvalid: 'cpf inválido',
					cnpjInvalid: 'cnpj inválido',
					emailInvalid: 'email inválido',
					success: 'sucesso',
				},
				registerImported: 'Registro Importado com sucesso',
			},

			pastDays: 'dia(s) passado(s)',
			imported: 'Importado',
			noImported: 'Não importado',

			tooltip: {
				seeDetails: 'Ver detalhes',
				removeImport: 'Remover histórico de importação',
			},

			alert: {
				title: 'Excluir Histórico de Importação',
				subtitle: 'Você tem certeza que deseja remover esse histórico de importação?',
			},
		},
		preferences: {
			security: {
				title: 'Segurança',
				subtitle:
					'Altere a senha da sua conta frequentemente para impedir o acesso não autorizado à sua conta.',
				emailAddress: 'Endereço de e-mail',
				password: 'Senha',
				redefinePassword: 'Redefinir senha',
				newPassword: 'Nova senha',
				confirmPassword: 'Confirme a nova senha',
				passMin: 'A senha deve ter pelo menos 8 caracteres e conter números e símbolos',
				updatePassword: 'Atualizar senha',
				twoFactor: {
					title: 'Autenticação de dois fatores',
					note: 'A autenticação de dois fatores adiciona uma camada extra de segurança à sua conta.',
					note2: 'Para fazer login, você também precisará fornecer um código de 6 dígitos.',
					soon: 'Em breve',
					enable: 'Habilitar',
					authenticatorApps: 'Apps autenticadores',
					sms: 'SMS',
					chooseMethod: 'Escolha um método de autenticação',
					methodNote:
						'Além do seu usuário e senha, você terá que inserir um código (entregue via aplicativo ou SMS) para fazer login em sua conta.',
					appsOption:
						'Obtenha códigos de um aplicativo como Google Authenticator, Microsoft Authenticator, Authy ou 1Password',
					smsOption:
						'Enviaremos um código por SMS se você precisar usar seu método de login de backup.',
				},
			},
			associatedAccounts: {
				title: 'Contas associadas',
				subtitle:
					'Veja e faça a gestão da lista de contas que estão associadas à sua conta ClusterVet.',
				connect: 'Conectar',
			},
			notifications: {
				title: 'Notificações',
				subtitle:
					'Veja e faça a gestão da lista de contas que estão associadas à sua conta ClusterVet.',
				activity: {
					title: 'Atividade',
					serviceEmail: {
						title: 'Receber um e-mail quando alguém solicitar um atendimento',
						subtitle:
							'Quando o cliente solicitar um atendimento, você será avisado através do email cadastrado.',
					},
				},
				application: {
					title: 'Aplicação',
				},
				contact: {
					title: 'Opções de contato',
				},
			},
			deleteAccount: {
				title: 'Excluir conta',
				subtitle:
					'Excluir permanentemente todas as informações de conta e dados de serviços.',
				note: 'Assim que exclúida, essa conta não estará mais disponível definitivamente.',
				note2: 'Por favor tenha certeza',
				delete: 'Excluir conta',
			},
		},

		webcam: {
			takePhotoWithWebcam: 'Capturar imagem com a webcam',
			videoUnavailable: 'Vídeo indisponível',
			permissionDenied:
				'Não foi possível ter acesso a webcam, para utilizar este recurso, libere o acesso a webcam através do seu navegador.',
			takePhoto: 'Tirar foto',
			takeAnotherPhoto: 'Tirar outra foto',

			feedback: {
				error: {
					convertToBlob: 'Ocorreu um erro ao converter a imagem',
				},
			},
		},

		webhooks: {
			integrations: 'Integrações',
			automateYourWork: 'Integre com suas ferramentas favoritas e automatize o seu trabalho!',
			labcloud: {
				name: 'Labcloud',
				apiKey: 'Chave de API',
				laboratoryId: 'ID do Laboratório',
				description:
					'Conecte com o labcloud e envie os laudos diretamente para seus clientes.',
				configurations: 'Configurações Labcloud',
				functions: {
					visualizeLabcloudClients: 'Visualizar clientes do labcloud',
					sendExamReports: 'Enviar laudos',
				},
			},
			asaas: {
				name: 'Asaas',
				apiKey: 'Chave de API',
				walletId: 'ID da carteira',
				paymentMethod: 'Método padrão de pagamento',
				configurations: 'Configurações Asaas',
				description: 'Conecte com o asaas e envie cobranças e receba pagamentos.',
				functions: {
					sendPayments: 'Enviar cobranças',
					syncronization: 'Sincronização com faturas ClusterVet',
				},
			},
		},

		bills: {
			feedback: {
				success: {
					removeBillItem: 'Sucesso ao remover o item',
				},
			},
			status: {
				pending: 'Pendente',
				paid: 'Pago',
				canceled: 'Cancelado',
				typing: 'Em Digitação',
				awaitingPayment: 'Aguardando Pagamento',
			},
		},
	},

	cropper: {
		cropImage: 'Ajustar imagem',
	},

	chips: {
		actions: {
			messageCreateItem: `Aperte "Enter" para criar um novo rótulo`,
			changeColor: 'Alterar Cor',
			rename: 'Renomear',
			remove: 'Remove',
		},

		message: 'Busque ou crie novos',
		messageNewTag: 'Busque ou crie novos rótulos',
		messageSearch: 'Busque os',
		messageSearchNotFound: 'Nenhum registro relacionado aos',

		tooltip: {
			preview: 'Visualizar rótulos',
		},
	},

	feedback: {
		feedback: 'Reportar Problema',
		sendFeedback: 'Enviar feedback',
		describeYourProblem: 'Descreva seu problema ou sugestão',
		howWeCanImprove: 'Diga como podemos melhorar nossa plataforma',
		sendImages: 'Enviar imagens',
		newScreenShot: 'Nova captura de tela',
		screenshot: 'Captura de tela',
	},

	notifications: {
		notifications: 'Notificações',
		unread: 'Não lidas',
		mentioned: 'Fui mencionado',
		assigned: 'Atribuídas a mim',
		nothingNew: 'Nada de novo por aqui.',
	},

	configuration: {
		menu: {
			users: 'Usuários',
			profileAcess: 'Perfil de Acesso',
			integrations: 'Integrações',
			importAndExport: 'Importar / Exportar',
			groups: 'Grupos',
			agreements: 'Convênios',
			customFields: 'Campos personalizados',
			attributes: 'Permissões',
		},

		registers: {
			species: 'Espécies',
			races: 'Raças',
			coats: 'Pelagens',
			illnesses: 'Doenças',
			typeOfService: 'Tipos de atendimento',
			vaccines: 'Vacinas',
			examsAndProfiles: 'Exames / Perfis',
			category: 'Categorias de exames',
			forms: 'Formulários',
			tags: 'Rótulos',
			materials: 'Materiais',
			recipients: 'Recipientes',
			methods: 'Métodos',
			equipments: 'Equipamentos',
			invoices: 'Faturas',
			bills: 'Faturas',
		},

		menu_user: {
			myProfile: 'Meu perfil',
			preferences: 'Preferências',
			organizations: 'Organizações',
			rewards: 'Recompensas',
		},

		menu_automations: {
			webhooks: 'Webhooks',
			triggersAndEvents: 'Gatilhos e Eventos',
			notifications: 'Notificações',
			apikey: 'Chave de API',
			integration: 'Integrações',
			webhook: 'Webhook',
		},

		custom_menu: {
			customFields: 'Campos personalizados',
			customerPortal: 'Portal do cliente',
			preferences: 'Preferências',
			emailTemplates: 'Modelos de e-mail',
		},

		financial_menu: {
			splits: 'Splits',
			paymentGateway: 'Gateways de pagamento',
			Preferences: 'Preferências',
		},

		menu_sessions: {
			activeSessions: 'Sessões ativas',
			activeTokens: 'Tokens ativos',
		},
	},

	menu: {
		feedback: 'Feedback',
		referer: 'Indique a ClusterVet',
		help: 'Ajuda e Suporte',
		invite: 'Convidar membros',
		office: 'Proprietário',
		office_personal: 'Conta pessoal',
		officeOwner: 'Proprietário',
		officeMember: 'Membro',
		officeTechnicalManager: 'Responsável Técnico',
		newSection: 'Criar nova seção',
		editSection: 'Editar seção',
		newMark: 'Nova marcação',
		editMark: 'Editar marcação',
		selectIcon: 'Selecionar icone',
		route: 'Caminho/Rota',
		expandedByDefault: 'Expandido por padrão',
		previewRoute: 'Visualizar rota',
		clickInTheIcon: 'Clique no ícone para selecionar',
	},

	menu_dropdown: {
		clinica: 'Clínica Veterinária',
		laboratorio: 'Laboratório Veterinário',
		petshop: 'Petshop',
		conta: 'Conta',
		explorar: 'Explorar',
		organizacoes: 'Minhas organizações',
		perfil: 'Meu Perfil',
		config: 'Configurações',
		integracao: 'Integrações',
		importacao: 'Importar dados',
		novo: 'O que há de novo',
		marketplace: 'Marketplace',
		clusterVetlabs: 'ClusterVet.labs',
		invite: 'Convidar membros',
		darkmode: 'Ativar modo escuro',
		lighmode: 'Ativar modo claro',
		sair: 'Sair',
		adicionaOrg: 'Adicionar Organização',
	},

	buttons: {
		save: 'Salvar',
		cancel: 'Cancelar',
		send: 'Enviar',
		register: 'Cadastrar',
		saveChanges: 'Salvar alterações',
		add: 'Adicionar',
		kick: 'Expulsar',
		remove: 'Remover',
		discard: 'Descartar alterações',
		ok: 'Ok',
		unlink: 'Desvincular',
		next: 'Prosseguir',
		close: 'Fechar',
		transferOwnership: 'Transferir Propriedade',
		select: 'Selecionar',
		confirm: 'Confirmar',
		printOut: 'Imprimir',
		buttonUseVersion: 'Utilizar Versão',
		resetFilters: 'Limpar filtros',
		finalize: 'Concluir',
		invite: 'Convidar Usuário',
	},

	resources: {
		attribute: 'Atributo',
		exam: 'Exame',
		animal: 'Animal',
		request: 'Requisição',
		form: 'Formulário',
		agreement: 'Convênio',
		examgroup: 'Perfis',
		coat: 'Pelagem',
		customer: 'Cliente',
		examcategory: 'Categoria de exame',
		import: 'Importar',
		recipient: 'Recipiente',
		breed: 'Raça',
		label: 'Rótulo',
		material: 'Material',
		users: 'Usuários',
		species: 'Espécie',
		requestedexams: 'Inserção de Resultado',
		requestedexam: 'Inserção de Resultado',
		method: 'Métodos',
		bill: 'Faturas',
		order: 'Pedidos',
	},

	actions: {
		create: 'Criar',
		read: 'Ler',
		update: 'Atualizar',
		delete: 'Remover',
	},

	default: {
		updatedAt: 'Atualizado em',
		selectAll: 'Selecionar tudo',
		removeAll: 'Remover tudo',
		default: 'Padrão',
		active: 'Ativo',
		attribute: 'Atributo',
		addedBy: 'Adicionado(a) por',
		inactive: 'Inativo',
		mandatory: 'Obrigatório',
		name: 'Nome',
		attributes: 'Atributos',
		users: 'Usuários',
		people: 'Pessoas',
		edit: 'Editar',
		creator: 'Criador',
		tags: 'Rótulos',
		remove: 'Remover',
		resources: 'Recursos',
		resource: 'Recurso',
		status: 'Status',
		send: 'Enviar',
		key: 'Chave',
		value: 'Valor',
		description: 'Descrição',
		optional: 'Opcional',
		cancel: 'Cancelar',
		preview: 'Pré visualização',
		change: 'Alterar',
		create: 'Criar',
		createdAt: 'Criado em',
		creationDateAndTime: 'Data e hora da criação',
		fieldName: 'Nome do campo',
		configurations: 'Configurações',
		context: 'Contexto',
		date: 'Data',
		duplicate: 'Duplicar',
		time: 'Tempo',
		forms: 'Formulários',
		freeTrial: 'Você está em um teste gratuito.',
		upgradeAccount: 'Fazer upgrade da conta?',
		addImage: 'Adicionar imagem',
		changeImage: 'Mudar imagem',
		members: 'Membros',
		member: 'Membro',
		invites: 'Convites',
		viewMode: 'Modo de visualização',
		all: 'Todos',
		color: 'Cor',
		financial: 'Financeiro',
		personalization: 'Personalização',
		permissions: 'Permissões',
		automations: 'Automações',
		registers: 'Cadastros',
		sessions: 'Sessões',
		back: 'Voltar',
		welcomeDashboard: 'Meu ClusterVet',
		you: 'Você',
		organization: 'Organização',
		inviteUser: 'Convidar usuários',
		unknown: 'Desconhecido',
		new: 'Novo',
		guest: 'Convidado',
		selectItem: 'Item selecionado',
		selectItems: 'Itens selecionados',
		none: 'Nenhum',
		view: 'Visualizar',
		amount: 'Qntd',
		subtitle: 'Legenda',
		uninformed: 'Não informado',
		select: 'Selecione',
		type: 'Tipo',
		updateColor: 'Atualizar cor',
		notFound: 'Nenhum registro',
		reset: 'Resetar',
		laboratory: 'Laboratório',
		price: 'Preço',
		dontCrmv: 'não possui um crmv',
		doctorVeternary: 'Médico Veterinário',
		ok: 'Ok',
		warning: 'Atenção!',
		searchTable: 'Buscando...',
		knowMore: 'Saiba mais',
		search: 'Buscar',
		required: 'Obrigatório',
		email: 'E-mail',
		situation: 'Situação',
		transferOwnership: 'Transferir Propriedade',
		actual: 'Atual',
		owner: 'Dono',
		clinic: 'Clínica',
		zipCode: 'CEP',
		verifiedByClusterVet: 'Verificado pela ClusterVet',
		fullName: 'Nome Completo',
		birthDate: 'Data de Nascimento',
		company: 'Empresa',
		client: 'Cliente',
		noPhone: 'Sem telefone',
		verified: 'Verificado',
		pending: 'Pendente',
		choose: 'Selecionar',
		loading: 'Carregando',
		entranceDate: 'Data de Entrada',
		samples: 'Amostras',
		requests: 'Requisições',
		exams: 'Exame | Exames',
		day: 'Dia',
		days: 'Dias',
		hour: 'Hora',
		hours: 'Horas',
		copy: 'Copiar',
		copied: 'Copiado',
		reason: 'Motivo',
		modeFullscreen: 'Abrir o Modo Tela cheia',
		exitModeFullScreen: 'Sair do Modo Tela cheia',
		selectFile: 'Selecionar arquivo',
		icon: 'Ícone',
		created: 'Criado',
		update: 'Atualizado',
		title: 'Título',
		waiting: 'Aguardando',
		pixels: 'Pixels',
		line: 'Linha',
	},
}
