export default {
	modules: {
		requisicoes: 'Requests',
		clientes: 'Clients',
		recipientes: 'Recipients',
		exames: 'Exam Registration',
		animais: 'Animals',
		demands: 'Demands',

		auth: {},

		generalize: {
			list: 'List',
			painting: 'Painting',
			timeline: 'Timeline',
			groupByStatus: 'Group by status',
			notStarted: 'Not started',
			started: 'Started',
			concluded: 'Concluded',
		},

		coats: {
			name: 'Coats',
			placeholderSearch: 'Search coats',

			table: {
				coat: 'Coat',
				notFound: 'No coats records found.',
			},

			form: {
				addCoat: 'Add Coat',
				editCoat: 'Edit Coat',
				title: 'Add Coat',
				coatName: 'Coat Name',
			},

			tooltip: {
				editCoat: 'Edit coat',
				removeCoat: 'Remove coat',
			},

			alert: {
				title: 'Delete Coat',
				subtitle: 'Are you sure you want to remove this coat?',
			},

			feedback: {
				success: {
					create: {
						coat: 'The coat was successfully created.',
					},

					update: {
						coat: 'The coat has been updated successfully.',
					},

					remove: {
						coat: 'The coat was successfully removed.',
					},
				},
			},
		},

		menu: {
			tooltip: {
				closeMenu: 'Close Menu',
				openMenu: 'Open Menu',
			},
		},

		shortcuts: {
			tooltip: {
				newAnimalTutor: 'New Patient/Tutor',
				newClient: 'New Client',
				newExam: 'New Exam',
				examRequest: 'Exam Request',
				newExamRequest: 'New Exam Request',
				newOrder: 'New Order',
				insertResult: 'Insertion of Results',
			},
		},

		agreements: {
			name: 'Agreements',
			placeholderSearch: 'Search agreements',

			table: {
				headers: {
					creator: 'Creator',
					createdIn: 'Created in',
					examName: 'Exam Name',
					examPrice: 'Exam Price',
					examPriceDiscount: 'Discount price',
				},

				notFound: 'No record of agreements found.',
				notFoundSelect: 'No exam was selected',
				notFoundExam: 'No exam found',
				notFoundSearch: 'No record',
			},

			model: {
				name: 'Agreement',
				newAgreement: 'New Agreement',
				title: 'Add Agreement',
				titleEditAgreement: 'Edit Agreement',
				descriptionAgreement: 'Agreement Description',
				applyDiscount: 'Apply discount',
				showPriceDiscount: 'Show the price as a discount?',
				defaultAgreement: 'Standard Agreement?',
				textDefaultAgreement:
					'If you select this agreement as default, the\n' +
					'\t\t\t\t\t\t\t\t\t\t\t\t\tprices will follow this agreement as\n' +
					'\t\t\t\t\t\t\t\t\t\t\t\t\treference!',
				selectAllExams: 'Select all Exams?',
				valueTables: 'Value Table',
				addAgreement: 'Add Agreement',
				biggerDiscountText:
					'The Agreement was applied only to exams that were greater than the discount',
				percentageBiggerDiscountText:
					'Percentage has not been applied because it is higher than the exam price',
				deleted: 'This agreement has been deleted.',
			},

			tooltip: {
				editAgreement: 'Edit agreement',
				removeAgreement: 'Remove agreement',
				downloadAgreement: 'Download agreement',
				unlinkExam: 'Unlink exam',
			},

			status: {
				default: 'Agreement Default',
				agreementByCustomers: 'Agreement by Customer(s)',
			},

			feedback: {
				success: {
					create: {
						agreement: 'The Agreement was successfully created.',
					},

					update: {
						agreement: 'The Agreement has been successfully updated.',
					},

					remove: {
						agreement: 'The Agreement was successfully removed.',
					},
				},
			},
		},

		exams: {
			name: 'Exams',
			notFound: 'No exam records found.',
			buttonNew: 'Create Exam',
			placeholder: 'Search for Exams...',
			examDefaultName: 'Unnamed exam',
			attributeGroupDefaultName: 'New Attribute group',
			attributeDefaultName: 'New Attribute',

			attributeTypes: {
				title: 'Type',
				simpleText: 'Simple Text',
				textarea: 'Long Text',
				text: 'Text',
				integer: 'Integer',
				decimal: 'Decimal',
				formula: 'Formula',
				date: 'Date',
				positiveNegative: 'Positive/Negative',
				image: 'Image',
				select: 'Multiple Choice',
				method: 'Method',
				percentage: 'Percentage',
			},

			bottomSheet: {
				examModels: 'Exam Templates',
				selectTemplateToNext: 'Select a model to proceed',
				clusterVetExamTemplates: 'ClusterVet Exam Templates',
				myExamsTemplates: 'My Exam Templates',
				textReadyModel: 'Ready-to-use templates. Edit only the content',
				inBlank: 'In blank',
				select: 'Select',
				save: 'Save',
				saveAndNew: 'Save and Insert New',
				examRegistration: 'Exam Registration',
				examEdition: 'Exam Edition',

				tab: {
					exam: 'Exam',
					referenceTables: 'Reference Tables',
					relatedExams: 'Related Exams',
				},

				related: {
					notFound: 'No relationships yet =(',
					textRelated:
						'To link one exam to another, click on the button below and link an attribute to another exam!',
					linkNewExam: 'Link New Exam',
					addAnotherRelationship: 'Add another Relationship',
					warning: 'Warning!',
					textAlert: 'You can only relate this exam from the registration.',
					subTextAlert: 'Do you want to save this exam now?',
					notSave: 'No',
					saveExam: 'Save Exam',
					disableRelated: 'Deactivate relationship',
					editRelated: 'Edit Relationship',
					unlinkRelated: 'Unlink Relationship',
					examOf: 'Exam of',
					attribute: 'Attribute',
				},

				reference: {
					warning: 'Warning!',
					textAlert: 'You can only reference this exam from the registration.',
					subTextAlert: 'Do you want to save this exam now?',
					notSave: 'No',
					saveExam: 'Save exam',
				},

				attribute: {
					name: 'Attribute',
					addOuther: 'Add another',
					group: 'Group',
					or: 'or',
				},
			},

			form: {
				isTemplate: 'Is Template',
				disableStatus: 'Deactivate the Exam',
				activeStatus: 'Activate the Exam',
				editExam: 'Edit Exam',
				unlinkExam: 'Unlink Exam',
				examName: 'Exam Name',
				period: 'Period',
				workingDays: 'Working days',
				hours: 'Hours',
				price: 'Price',
				reference: 'Reference',
				referenceTooltip: 'Used for external identification (Optional)',
				category: 'Category',
				addCategory: 'Add category',
				notFoundRecipient: 'No record',
				notFoundCategory: 'No record',
				notFoundForm: 'No record',
				notFoundEquipment: 'No record',
				notFoundMethod: 'No record',
				notFoundMaterial: 'No record',
				notResultForm: 'No Form Found',
				notResultEquipment: 'No Equipment Found',
				notResultMethod: 'No Method Found',
				notResultMaterial: 'No Material Found',
				recipients: 'Recipients',
				amountSample: 'Amount Sample',
				descriptionSample: 'Sample Description',
				sampleCollectionInstructions: 'Sample collection instructions',
				technicalManager: 'Technical manager',
				tm: 'pathologists',
				notFoundTM: "You don't have a Technical Manager yet",
				form: 'Form',
				select: 'Select',
				equipment: 'Equipment',
				useds: 'used(s)',
				method: 'Method',
				methodUsed: 'Used (Technique)',
				methodDescription: 'Method Used [Technique] (Optional)',
				materialUsed: 'Material used (Optional)',
				materialUsedOptional: 'used (Optional)',
				samples: 'Sample',
				automatic: 'Automatic',
				addRecipient: 'Add recipient',
				addMaterial: 'Add material',
				addMethod: 'Add method',
				removeExam: 'Remove Exam',
				selectUnit: 'Select a unit',
			},

			modal: {
				addExamsProfiles: 'Add exams and profiles',
			},

			table: {
				header: {
					sigla: 'Sigla',
					examName: 'Exam name',
					price: 'Price',
					period: 'Period',
					method: 'Method',
					status: 'Status',
				},
			},

			referenceTable: {
				warning: 'Warning',
				doNotHaveReferences: 'This Exam does not yet have reference tables',
				canSaveLikeThat:
					'You can save this way, but the exam will be saved with inactive status.',

				buttons: {
					yes: 'Yes, save anyway',
					no: 'No, back to editing',
				},

				referenceNote: 'Here you can set reference values to compare in your exam results.',
				createNewTable: 'Create New Table',
				searchRefTable: 'Search reference table',
				learnMore: 'Learn more',

				table: {
					exam: 'Exam',
					specie: 'Specie',
					refTable: 'Reference table',
					ageGroup: 'Age group',
					noRegisterFound: 'No reference records found.',
					from: 'From',
					to: 'To',
					years: 'years',
					months: 'months',
				},

				form: {
					addReferences: 'Add Reference',
					editReferences: 'Edit Reference',
					selectExamNote:
						'Select the exam you want to create the reference table, then add the attribute values corresponding to the species.',
					messageObservation:
						'Note: This exam will only be referenced for the patient with that age.',
					name: 'Name *',
					specie: 'Specie *',
					initialAge: 'Initial Age *',
					finalAge: 'Final Age *',
					periodIn: 'Period in',
					notFoundSpecie: 'No species found',
					noRecord: 'No records',
					years: 'Years',
					months: 'Months',
					insertConditional: 'Insert Conditionals',

					header: {
						attribute: 'Attribute',
						complement: 'Complement',
						minRef: 'Minimum Ref.',
						maxRef: 'Maximum Ref.',
						unit: 'Unit',
					},

					label: {
						nameTable: 'Name for table identification',
						selectSpecie: 'Select specie',
					},
				},
			},

			alert: {
				title: 'Remove Exam',
				subtitle: 'Are you sure you want to remove this exam?',

				discardTitle: 'Discard Changes',
				discardSubtitle: 'are you sure you want to discard these changes?',
			},

			feedback: {
				success: {
					create: {
						exam: 'The exam was created successfully.',
					},

					update: {
						exam: 'The exam has been updated successfully.',
						examStatus: 'The exam status has been updated.',
					},

					remove: {
						exam: 'The exam was successfully removed.',
					},
				},

				errors: {
					EXAM_HAS_REQUESTED_EXAM_RELATIONSHIPS: {
						title: 'Exam is already being used.',
						message: 'The exam cannot be deleted as it is already being used.',
					},
				},
			},
		},

		examsGroup: {
			name: 'Exams Group',

			feedback: {
				success: {
					create: {
						examsGroup: 'The exam cluster was created successfully.',
					},

					update: {
						examsGroup: 'The exam grouping has been updated successfully.',
						examsGroupStatus: 'Exam cluster status has been updated successfully.',
					},

					remove: {
						examsGroup: 'The exam cluster was successfully removed.',
						unlinkExam: 'The exam was successfully unlinked.',
					},
				},
			},
		},

		category: {
			name: 'Categories',
			title: 'Did you know that you can group your exams by categories?',
			searchExamsChips: 'Search related exams',

			addCategory: 'Add Category',

			table: {
				notFound: 'No category records found.',
				category: 'Category',
				exams: 'Exams',
			},

			form: {
				addCategory: 'Add Category',
				editCategory: 'Edit Category',
				categoryName: 'Category name',
			},

			tooltip: {
				editCategory: 'Edit category',
				removeCategory: 'Remove category',
				notFoundExam: 'No exam',
				examCategory: 'Exam Category',
			},

			alert: {
				title: 'Remove Category',
				subtitle: 'Are you sure you want to remove this category?',
			},

			feedback: {
				success: {
					create: {
						category: 'Category created successfully',
					},

					update: {
						category: 'Category updated successfully',
					},

					remove: {
						category: 'Category removed successfully',
					},
				},
			},
		},

		species: {
			name: 'Species',
			noAnimal: 'No animal',
			addSpecie: 'Add Specie',

			table: {
				specie: 'Specie',
				notFound: 'No species records found.',
			},

			form: {
				addSpecie: 'Add specie',
				editSpecie: 'Edit Specie',
				specieName: 'Species name',
			},

			tooltip: {
				editSpecie: 'Edit species',
				removeSpecie: 'Remove species',
			},

			alert: {
				title: 'Remove Specie',
				subtitle: 'Are you sure you want to remove this species?',
			},

			feedback: {
				success: {
					create: {
						specie: 'The species was successfully bred.',
					},

					update: {
						specie: 'The species was successfully updated.',
					},

					remove: {
						specie: 'The species was successfully removed.',
					},
				},
			},
		},

		materials: {
			name: 'Materials',
			descriptionPlan: 'Manage the materials that will be used in the registration of Exams.',

			addMaterial: 'Add Material',

			form: {
				title: 'Add Material',
				titleEdit: 'Edit Material',
				addMaterial: 'Add Material',
				materialName: 'Material Name',
			},

			table: {
				notFound: 'No material records found.',
			},

			tooltip: {
				editMaterial: 'Edit material',
				removeMaterial: 'Remove material',
			},

			alert: {
				title: 'Remove Material',
				subtitle: 'Are you sure you want to remove this material?',
			},

			feedback: {
				success: {
					create: {
						material: 'Material created successfully',
					},

					update: {
						material: 'Material successfully updated',
					},

					remove: {
						material: 'Material successfully removed',
					},
				},
			},
		},

		races: {
			name: 'Races',

			addRace: 'Add Race',

			form: {
				title: 'Add Race',
				titleEdit: 'Edit Race',
				addRace: 'Add Race',
				existRace: 'There is already a race with that name',
				raceName: 'Race name',
				specie: 'Specie',
				specieNotFound: 'No Species Found',
				minimumWeight: 'Minimum weight (Optional)',
				minimum: 'Minimum',
				maximumWeight: 'Max weight (Optional)',
				maximum: 'maximum',
			},

			table: {
				specie: 'Specie',
				breed: 'Breed',
				notFound: 'No breed records found.',
			},

			tooltip: {
				editRace: 'Edit race',
				removeRace: 'Remove race',
			},

			alert: {
				title: 'Remove Race',
				subtitle: 'Are you sure you want to remove this race?',
			},

			feedback: {
				success: {
					create: {
						breed: 'The breed was successfully created.',
					},

					update: {
						breed: 'The race has been successfully updated.',
					},

					remove: {
						breed: 'The race was successfully removed.',
					},
				},
			},
		},

		address: {
			form: {
				addAddress: 'Add Address',
				editAddress: 'Edit Address',
				identifyAddress: 'How do you want to identify this address?',
				note: 'Note:',
				facilityText: 'This will help to facilitate your search for services or promotion.',
				address: 'Address',
				state: 'State',
				number: 'Number',
				city: 'City',
				neighborhood: 'Neighborhood',
				complement: 'Complement',
				zipCode: 'Zip Code',
			},
		},

		profiles: {
			name: 'Profiles',
			notFound: 'No profile records found.',
			buttonNew: 'Create Profile',
			placeholder: 'Search for Profiles...',

			form: {
				editProfile: 'Edit profile',
				newProfile: 'New Profile',
				examsPartProfile: 'Exams that are part of the profile',
				discount: 'Discount',
				totalProfileValue: 'Total Profile Value',
				addProfile: 'Add profile',
				removeProfile: 'Remove profile',
			},

			table: {
				header: {
					sigla: 'Sigla',
					profileName: 'Profile name',
					description: 'Description',
					price: 'Price (R$)',
					status: 'Status',
				},
			},

			alert: {
				title: 'Unlink Exam?',
				subtitle: 'Are you sure you want to unlink the exam from this profile?',
				titleRemoveProfile: 'Remove Profile',
				subtitleRemoveProfile: 'Tre you sure you want to remove this profile?',
			},

			tooltip: {
				activeStatusExamGroup: 'Activate exam group',
				disabledStatusExamGroup: 'Deactivate exam group',
			},
		},

		requests: {
			name: 'Requests',
			request: 'Request',

			table: {
				header: {
					numberRequest: 'Nº Requisition',
					requester: 'Requester',
					patient: 'Patient',
					progress: 'Progress',
					start: 'D.H. Start',
					request: 'D.H. Request',

					exams: {
						sigle: 'Sigle',
						exam: 'Exam',
						andament: 'Andament',
						rt: 'T.M',
						recipients: 'Recipients',
						period: 'Period',
					},
				},
				insertResult: 'Insert Result',
				viewRequest: 'View Request',
				searchExamPlaceHolder: 'Search for Exams...',
				noOwnerFound: 'No owner found.',
				noAnimalFound: 'No animal found.',
				noDoctorFound: 'No doctor found.',
				noTagFound: 'No tag found.',
				noLaboratoryFound: 'No laboratory found.',
				notStarted: 'Not started',
				notFound: 'No requisition records found.',
				search: 'Search Requests',
				selectCriterFilter: 'Select a filtering criterion',
				newRequest: 'New Request',
				newOrder: 'New Order',
				filterByDate: 'Filter by Date',
				andaments: {
					name: 'Progress',
					draft: 'Draft',
					new: 'New',
					newItem: 'New',
					waitingForRoute: 'Waiting for Route',
					inTransit: 'In transit',
					noncompliance: 'Non-compliance',
					inProgress: 'In Progress',
					processing: 'Processing',
					finished: 'Finished',
					analyse: 'Under analysis',
					typed: 'Typed',
					released: 'Released',
					waiting: 'Waiting',
					canceled: 'Canceled',
				},
				criters: {
					examProgress: 'Exam progress',
					RequestProgress: 'Request progress',
					examLabels: 'Exam labels',
					urgentRequests: 'Urgent requests',
				},
				progress: {
					canceled: 'Canceled',
					waitingForSample: 'Waiting for Sample',
					prevented: 'Prevented',
					processing: 'Processing',
					concluded: 'Concluded',
				},
				notHaveProfilesExams: 'This lab does not have profiles or exams',
				noResultsFilter: 'No results for this filter',
			},

			checkout: {
				order: 'Order',
				infoPatient: 'Patient Information',
				patient: 'Patient',
				retractCart: 'Retract cart',
				expandCart: 'Expand cart',
				allPrice: 'Value Total',
				allPriceDiscount: 'Value Total',
				discount: 'Disc',
				valueAll: 'Amount',
				discountValue: 'Discount Value',
				totalDiscount: 'Total with Discount',

				tooltip: {
					descriptionBelongsExam: 'This exam belongs to the profile',
				},

				examDescription: 'Exam Description',

				alert: {
					descriptionErrorDoctor:
						'This laboratory does not have medical-type clients to make the request.',
					descriptionErrorCustomers:
						"You don't have clients, please add medical type clients to be able to make the request.",
				},
			},
		},

		receiving: {
			name: 'Receiving',
			viewRequest: 'View request',
			editRequest: 'Edit request',
			removeRequest: 'Remove request',
			cancelRequest: 'Cancel request',
			cancelSample: 'Cancel sample',
			report: 'Report',
			notStarted: 'Not Started',
			inProgress: 'In progress',
			finished: 'Finished',
			notFound: 'Not receiving records found',

			header: {
				draftMode: 'Draft Mode',
				saving: 'Saving...',
				done: 'Done',
				newRequest: 'New Request',
			},

			tabs: {
				notes: 'Notes',
				exams: 'Exams',
				requests: 'Requests',
				logistic: 'Logistic',
				payments: 'Payments',
				billings: 'Billing',
			},

			tabContent: {
				notes: {},
				exams: {
					addExamOrProfile: 'Add Exams/Profiles',
				},
				logistic: {
					addShipping: 'Add Shipping',
				},
				payments: {
					addPayment: 'Add Payment',
				},
				billings: {
					addBilling: 'Add Billing',
				},
			},

			sidebar: {
				samples: 'Patient Sample',
				addSample: 'Add sample',
				forms: 'Forms',
				lastRequests: 'Last requests:',
				answerForm: 'Answer',
			},

			form: {
				title: 'New Request',
				subtitle: 'Select the fields you want to copy to the new requisition',
				order: 'Order',
				veterinarian: 'Veterinary doctor',
				patient: 'Patient',
				samples: 'Sample',
				exams: 'Exams',
				logistics: 'Logistics',
				attributes: 'Attributes',
			},

			attachmentForm: {
				title: 'Add attachment',
				titleForm: 'Title',
				description: 'Description',
				attach: 'Attach',
				relate: 'Relate',
			},

			sampleForm: {
				title: 'Add sample',
				titleEdit: 'Edit sample',
				details: 'Details',
				material: 'Material',
				recipient: 'Recipient',
				volume: 'Volume',
				unitOfMeasurement: 'Unit of measurement',
				deadline: 'Deadline',
				deadlineType: 'Term type',
				sampleOrigin: 'Sample origin',
				exams: 'Exams | {count} Exam | {count} Exams',
				samples: 'Sample | {count} Sample | {count} Sample',
				attachments: 'Attachments | {count} Attachment | {count} Attachments',
				relateExam: 'Relate Exams',
				relateSample: 'Relate Sample',
				reportSample: 'The report will be added to all exams linked to the sample',
				sampleReports: 'Observation of samples',

				alert: {
					title: 'Remove sample relationship',
					subtitle:
						'To create {count} samples, sample relationships will be removed, do you want to continue?',
				},
				tooltip: {
					amountToCreate1:
						'Number of samples to be created. If the value is greater than 1,',
					amountToCreate2: 'it will not be possible to relate child samples',
				},
			},

			logisticForm: {
				title: 'Register gathering',
				samples: 'Sample',
				shipping: 'Shipping',
				provider: 'Provider',
				status: 'Status',
				cost: 'Cost',
			},

			paymentForm: {
				title: 'Add payment',
				editPayment: 'Edit payment',
				payer: 'Paying',
				value: 'Valor',
				attachment: 'Attachment',
				relate: 'Relate',
				transaction: 'Transaction',
				titleAttachment: 'Describe the voucher',
				descriptionAttachment: 'Comments',
				receipt: 'Receipt',
			},

			billingForm: {
				title: 'New Invoice',
			},

			selectOrderForm: {
				title: 'Select Order',
				order: 'Order',
				observation: 'Observation',
				clinic: 'Clinic',
				selectClinic: 'Select the client',
				selectClinic2: 'Select the client responsible for the order',
			},

			requestStatus: {
				draft: 'Draft',
				draftPrefix: 'Draft',
				new: 'New',
				newPrefix: 'New',
				waitingForRoute: 'Waiting for Route',
				waitingForRoutePrefix: 'Waiting Route',
				inTransit: 'In transit',
				inTransitPrefix: 'In transit',
				nonCompliance: 'Non-compliance',
				nonCompliancePrefix: 'Non-compliance',
				inProgress: 'In Progress',
				inProgressPrefix: 'In Progress',
				finished: 'Finished',
				finishedPrefix: 'Finished',
				canceled: 'Canceled',
				canceledPrefix: 'Canceled',
				awaitingSample: 'Awaiting Sample',
				awaitingSamplePrefix: 'Awaiting Sample',
				received: 'Received',
				receivedPrefix: 'Received',
				processing: 'Processing',
				processingPrefix: 'Processing',
				waitingForAnalysis: 'Waiting for analysis',
				waitingForAnalysisPrefix: 'Waiting for analysis',
				awaitingAuthorization: 'Awaiting Authorization',
				awaitingAuthorizationPrefix: 'Awaiting Authorization',
				inAnalysis: 'In Analysis',
				inAnalysisPrefix: 'In Analysis',
				typing: 'In Typing',
				typingPrefix: 'In Typing',
				inValidation: 'In Validation',
				inValidationPrefix: 'In Validation',
				awaitingRelease: 'Awaiting Release',
				awaitingReleasePrefix: 'Awaiting Release',
				sent: 'Sent',
				sentPrefix: 'Sent',
			},

			logisticStatus: {
				notAssigned: 'Not Assigned',
				assigned: 'Assigned',
				accepted: 'Accepted',
				onRoute: 'On route',
				finished: 'Finished',
				canceled: 'Canceled',
			},

			sampleStatus: {
				onSchedule: 'On Schedule',
				scheduledCollection: 'Scheduled Collection',
				inTransit: 'In transit',
				nonCompliance: 'Non-compliance',
				collected: 'Collected',
				received: 'Received',
				discarted: 'Discarted',
				awaitingSample: 'Awaiting Sample',
				inAnalysis: 'In Analysis',
				canceled: 'Canceled',
				expired: 'Expired',
				archived: 'Archived',
			},

			alerts: {
				success: {
					requestCreated: 'Request created successfully',
					requestUpdated: 'Request updated successfully',
					requestCanceled: 'Request canceled successfully',
					sampleCanceled: 'Sample canceled successfully',
					observationCreated: 'Note created successfully',
					observationUpdated: 'Note successfully updated',
					observationDeleted: 'Note successfully removed',
					examAdded: 'Exam added successfully',
					examUpdated: 'Exam updated successfully',
					examDeleted: 'Exame removido com sucesso',
					payerAdded: 'Payer successfully added',
					payerDeleted: 'Payer successfully removed',
					sampleCreated:
						'Sample created successfully | {count} samples created successfully',
					sampleUpdated: 'Sample successfully updated',
					sampleDeleted: 'Sample successfully removed',
					examsDeleted: 'Exams removed successfully',
				},

				removeExam: {
					title: 'Remove Exam',
					subtitle: 'Are you sure you want to remove this exam?',
					titleMultipleExams: 'Remove Exams',
					subtitleMultipleExams: 'Are you sure you want to remove all selected exams?',
				},

				removeExamGroup: {
					title: 'Remove Exam Group',
					subtitle: 'Are you sure you want to remove this exam group?',
					titleMultipleExamGroups: 'Remove Exam Groups',
					subtitleMultipleExamGroups:
						'Are you sure you want to remove all selected exam groups?',
				},

				error: {
					cantDeleteSentExams: 'You cannot delete completed exams',
				},
			},

			tooltip: {
				copyRequest: 'Copy Request',
				nextStatus: 'Next status',
			},
		},

		attachments: {
			name: 'Attachments',

			alert: {
				title: 'Remove Attachment',
				subtitle: 'Are you sure you want to remove this attachment?',
			},

			feedback: {
				success: {
					create: {
						attachment: 'Attachment successfully added',
					},

					remove: {
						attachment: 'Attachment successfully removed',
					},
				},
			},

			tooltip: {
				removeAttachment: 'Remove attachment',
			},
		},

		insertResult: {
			name: 'Result Insertion',

			signature: 'Signature',
			sendReport: 'Send Report',
			completeReport: 'Complete Report',
			resendReport: 'Resend report',
			reportSent: 'Report sent',
			tutor: 'Tutor',
			veterinarian: 'Veterinarian',
			patient: 'Patient',
			clinic: 'Clinic',
			details: 'Details',
			history: 'History',
			patientSamples: 'Patient Sample',
			viewExam: 'View exam',
			editExam: 'Edit exam',
			removeExam: 'Remove exam',
			cancelExam: 'Cancel exam',

			signatureForm: {
				title: 'Add signature',
				pathologist: 'Pathologist',
				signature: 'Signature',
				saveSignature: 'Save signature',
				sign: 'Sign',
			},

			sendReportForm: {
				title: 'Send Report',
				sendImmediately: 'Send Immediately',
				sendWithTimer: 'Send with Timer',
				sendUsingV1: 'Send using V1',
				sendUsingV2: 'Send using V2',
				sendAllReports: 'Send all reports',
			},

			tooltip: {
				timer: 'Time for the report to be sent',
				pathologistSignature: "Pathologist's signature",
				showVersionamentHistory: 'Version History',
				useVersionEditable: 'Use this Exam Version',
				updateExamLayout: 'Reset layout',
				clearFields: 'Clear fields',
			},

			alert: {
				titleSign: 'Remove Sign',
				subtitleSign: 'Are you sure you want to remove the sign?',
				titleOldVersion: 'Old Version',
				subtitleOldVersion: 'You are entering an old version, do you want to continue?',
				titleCancelExam: 'Cancel Exam',
				subtitleCancelExam:
					'Are you sure you want to cancel this exam? After that you will be redirected to the home screen.',
			},

			examResultStatus: {
				new: 'New',
				newPrefix: 'New',
				underAnalysis: 'Under Analysis',
				underAnalysisprefix: 'Under Analysis',
				typing: 'In Typing',
				typingPrefix: 'Typing',
				toSign: 'To sign',
				toSignPrefix: 'To sign',
				completed: 'Completed',
				completedPrefix: 'Completed',
				canceled: 'Canceled',
				canceledPrefix: 'Canceled',
				waitingForAnalysis: 'Waiting for analysis',
				waitingForAnalysisPrefix: 'Waiting for analysis',
				awaitingAuthorization: 'Awaiting Authorization',
				awaitingAuthorizationPrefix: 'Awaiting Authorization',
				inAnalysis: 'In Analysis',
				inAnalysisPrefix: 'In Analysis',
				inValidation: 'In Validation',
				inValidationPrefix: 'In Validation',
				awaitingRelease: 'Awaiting Release',
				awaitingReleasePrefix: 'Awaiting Release',
				sent: 'Sent',
				sentPrefix: 'Sent',
				nonCompliance: 'Non Compliance',
				nonCompliancePrefix: 'Non Compliance',
				examType: 'Exam Type',
				interfaced: 'Interfaced',
			},

			errorsForm: {
				requestedExamNotFound: 'Requested Exam not found.',
				passwordIncorrect: 'The password is incorrect.',
				signedAlready: 'This result is already signed.',
				unknownError: 'An unknown error has occurred.',
			},

			addon: {
				name: 'Addon',
				addons: 'Additional',
				titleModal: 'Select Addon',
				titleModalAddAddon: 'Add Addon',
				addManually: 'Add Manually',
				editAddon: 'Edit Addon',
				removeAddon: 'Remove Addon',
				newAddon: 'New Additional',
				notFound: 'No additional was found.',
				messageRemoveAddon: 'After the save action, this Addon will be removed.',
				totalAddons: 'Total',

				alert: {
					title: 'Remove Addon',
					subtitle: 'Are you sure you want to remove this add-on?',
				},

				tooltip: {
					addAddon: 'Include Additional',
					messageOtherPageSelected:
						'Careful! It has addon(s) added, which are on another page(s).',
				},
			},

			feedback: {
				success: {
					create: {
						version: 'A new version has been created successfully',
						reportSent: 'Report successfully updated',
						signature: 'Signature added successfully',
					},

					update: {
						version: 'Report successfully updated',
					},

					remove: {
						signature: 'Signature removed successfully',
					},
				},
			},
		},

		financial: {
			alert: {
				title: 'Remove Payment',
				subtitle: 'Are you sure you want to remove the payment?',
			},

			tooltip: {
				editPayment: 'Edit Payment',
				removePayment: 'Remove Payment',
			},

			table: {
				paying: 'Paying',
				receipt: 'Receipt',
				registeredBy: 'Registered by',
				transaction: 'Transaction',
				value: 'Value',
			},

			typePaymentMethods: {
				other: 'other',
				bankingBillet: 'Banking Billet',
				paymentLink: 'Payment Link',
				instantPayment: 'PIX - Instant Payment',
				pixCharge: 'Pix Charge',
				fidelityProgram: 'Fidelity Program',
				creditCard: 'Credit Card',
				debitCard: 'Debit Card',
				withoutPayment: 'Without Payment',
				bankingTransfer: 'Banking Transfer',
				foodVoucher: 'Food Voucher',
				fuelVoucher: 'Fuel Voucher',
				giftVoucher: 'Gift Voucher',
				mealVoucher: 'Meal Voucher',
				digitalWallet: 'Digital Wallet',
				cashback: 'Cashback',
				check: 'check',
				virtualCredit: 'Virtual Credit',
				storeCredit: 'Store Credit',
				cash: 'Cash',
				bankingDeposit: 'Banking Deposit',
			},

			feedback: {
				success: {
					create: {
						payment: 'Payment created successfully',
					},

					update: {
						payment: 'Payment updated successfully',
					},

					remove: {
						payment: 'Payment removed successfully',
					},
				},
			},
		},

		invoices: {
			status: {
				pending: 'Pending',
				paid: 'Paid',
				typing: 'In typing',
				awaitingPayment: 'Awaiting Payment',
				canceled: 'Canceled',
			},
		},

		previousResults: {
			table: {
				notFound: 'No previous results found.',
			},
		},

		orders: {
			name: 'Orders',
			order: 'Order',
			createdBy: 'Created by',
			copyOrderLink: 'Copy order link',
			viewOrder: 'View order',
			editOrder: 'Edit order',
			removeOrder: 'Delete order',
			cancelOrder: 'Cancel order',
			newOrder: 'New Order',
			notFound: 'No order record found',

			newOrderForm: {
				title: 'New order',
			},

			form: {
				cancelOrder: 'Cancel order',
			},

			table: {
				search: 'Search orders',
			},

			status: {
				result: 'Result',
				draft: 'Draft',
				draftPrefix: 'Draft',
				new: 'New',
				newPrefix: 'New',
				inLogistics: 'In Logistics',
				inLogisticsPrefix: 'In LGSTS.',
				processing: 'Processing',
				processingPrefix: 'Processing',
				delivered: 'Delivered',
				deliveredPrefix: 'Delivered',
				billed: 'Billed',
				billedPrefix: 'Billed',
				finished: 'Finished',
				finishedPrefix: 'Finished',
				canceled: 'Canceled',
				canceledPrefix: 'Canceled',
				budget: 'Budget',
				awaitingSample: 'Awaiting Sample',
				awaitingPayment: 'Awaiting Payment',
				received: 'Received',
				awaitingRelease: 'Awaiting Release',
			},

			feedback: {
				success: {
					create: {
						order: 'Order created successfully',
					},

					update: {
						order: 'Order successfully updated',
					},

					remove: {
						order: 'Order deleted successfully',
					},

					cancel: {
						order: 'Order canceled successfully',
					},
				},
			},
		},

		navigationDrawer: {
			search: 'Search',
			orderBy: 'Order by',
			sortOrder: 'Sort order',
			filterByDate: 'Filter by date',
			examPriority: 'Exam Priority',
			examCategory: 'Exam Category',
			examStatus: 'Exam Status',
			filterByBilling: 'Filter by billing',

			sortBy: {
				status: 'Status',
				priority: 'Priority',
				price: 'Price',
				discount: 'Discount',
				dueDate: 'Due date',
				createdDate: 'Creation Date',
				entranceDate: 'Entrance Date',
				lastUpdate: 'Last update',
				ascending: 'Ascending',
				descending: 'Descending',
				yes: 'Yes',
				no: 'No',
				protocolNumber: 'Protocol Number',
				issuedAt: 'Issued at',
			},

			request: {
				filterRequests: 'Filter Requests',
				canSearchBy1: 'You can search by: short protocol number,',
				canSearchBy2: 'protocol number, name of client, veterinarian or clinic.',
				requestPriority: 'Request priority',
				requestStatus: 'Request Status',
			},

			exam: {
				filterExams: 'Filter exams',
			},

			bill: {
				filterBills: 'Filter bills',
			},

			order: {
				filterOrder: 'Filter orders',
				orderStatus: 'Order status',
				orderPriority: 'Order priority',
			},
		},

		animals: {
			name: 'Patients',

			searchPlaceholder: 'Search patient',
			alive: 'Alive',
			dead: 'Dead',
			castrated: 'Castrated',
			doctor: 'Doctor',
			responsableExist: 'They exist',
			descriptionDoctorResponsable: 'doctors responsible for the patient',
			descriptionTutorResponsable: 'guardians responsible for the patient',
			notFoundTutors: 'Does not have a tutor',
			notFoundDoctors: 'Does not have a doctor',

			table: {
				headers: {
					animalTutor: 'Patient / Tutor',
					animal: 'Patient',
					raceSpecie: 'Race / Species',
					sex: 'Sex',
					age: 'Age',
				},

				notFound: 'No patient records found.',
			},

			header: {
				backToList: 'Back to patient list',
				tutor: 'Tutor',
				editAnimal: 'Edit patient',
			},

			form: {
				title: 'New Patient',
				editAnimal: 'Edit Patient',
				generateNewAUID: 'Generate New AUID',
				uniqueIdentification: 'Unique Identification of the Patient',
				animalName: 'Patient name',
				birthDate: 'Birth date',
				year: 'Years (Calculate Date)',
				days: 'Days (Calculate Date)',
				yearNotFound: 'No Year found.',
				month: 'Months (Calculate Date)',
				monthNotFound: 'No Month found.',
				specie: 'Species',
				raceNotFound: 'No races found.',
				sex: 'Sex',
				coat: 'Coat',
				fertility: 'Fertility',
				weight: 'Weight',
				microchip: 'Microchip',
				animalRegistration: 'Patient registration',
				observations: 'Comments',
				tutorOrDoctor: 'Tutor or Veterinary Doctor',
				selectRegisterAnimal: 'Select or register those responsible for the patient',
				searchTutorExisting: 'Search for one',
				searchTutorExisting2: 'existing or register a new one.',
				selectTutorClient: 'Select a Tutor/Customer',
				doctor: 'veterinarian',
				selectDoctorClient: 'Select a Veterinarian',
				addAnimal: 'Add Patient',
				addPatient: 'Include Patient',
				note1: 'Try to record as much information about the patient as possible!',
				note2:
					'The more accurate the information entered, the more\n' +
					'\t\t\t\t\t\t\t\t\tsmart the platform will become.',
				import: 'Import',
			},

			bottomSheet: {
				allCustomerFound: 'Total Customers Found',
				allCustomer: 'Total Customers',
				searchPlaceholder: 'Search by Name, Cpf, Phone, Email...',
				doctor: 'Veterinarian',
				clinic: 'Clinic',
				doctorSimple: 'doctor',
				tutor: 'Tutor',

				alert: {
					existCustomerType: 'There are Customers of the type',
					to: ', for',
					viewCustomer: 'view the Customer',
					hereYouShould: 'here you should',
					updateRegister: 'Update Registration',
				},

				selectClientType: 'Select customer type',
				titleNotFound: "We didn't find any results for your search.",
				descriptionNotFound:
					'Try to redo your search using more characters or create a new record using the\n' +
					'\t\t\tbutton below.',
				selectTutorDoctor: 'Select Tutor/Physician',
				chooseAnimal: 'Choose an patient',
				selectAnimal: 'Select patient',
				hideInfoAnimal: 'Hide patient from',
				viewInfoAnimal: 'View patient from',
				hideInfoContact: 'Hide contact information',
				viewInfoContact: 'View contact information',
				telephone: 'Telephone',
				textRegister1: 'You can register',
				textRegister2: 'a',
				textRegister3: 'New',
				register: 'Register',
				alreadyRegisterAnimal:
					'You are already registering an patient, please select the owner or doctor',
				clientType: 'Client type',
				client: 'Client',
				animal: 'Patient',
				simpleAnimal: 'patient',
			},

			tooltip: {
				animalDetails: 'Patient Details',
				removeAnimal: 'Remove Patient',
			},

			details: {
				breed: 'Breed',
				specie: 'Specie',
				size: 'Size',
				tutor: 'Tutor',
				coat: 'Coat',
				noCoat: 'No coat',
				sex: 'Sex',
				age: 'Age',
				death: {
					name: 'Dead',
					yes: 'Yes',
					no: 'No',
					uninformed: 'Uninformed',
				},
				male: 'Male',
				female: 'Female',
				noSex: 'Undefined',
				day: 'day',
				days: 'days',
				month: 'month',
				months: 'months',
				year: 'year',
				years: 'years',
				categories: {
					history: 'History',
					weights: 'Weights',
					appointments: 'Appointments',
					vaccines: 'Vaccines',
					prescriptions: 'Prescriptions',
					exams: 'Exams',
					medicines: 'Medicines',
					documents: 'Documents',
					observation: 'Observation',
				},
			},

			customer: {
				doesntHave: "This client doesn't have a pet yet! =D",
				note: 'For an patient to be registered, simply add an patient with ',
				note2: 'the name of this customer that the patient will be computed.',
				startRegister: 'Start patient registration',
			},

			feedback: {
				success: {
					create: {
						animal: 'Patient successfully created',
					},

					update: {
						animal: 'Patient successfully updated',
					},

					remove: {
						animal: 'Patient successfully removed',
					},
				},
			},
		},

		customers: {
			name: 'Customers',

			addCustomer: 'Add Customer',
			bin: 'Bin',
			costumerList: 'Customer list',
			import: 'Import',

			table: {
				headers: {
					nameSocialReason: 'Name / Company Name',
					phone: 'Phone',
				},
				notFound: 'No customer records found.',
				noPhone: 'No phone',
				verified: 'Verified',
				pending: 'Pending',
			},

			header: {
				averageTicket: 'Average Ticket',
				accomplishedServices: 'Accomplished services',
				schedules: 'Schedules',
			},

			placeholders: {
				selectCustomerType: 'Select customer type',
			},

			customerDetails: {
				customerData: 'Registration data',
				phones: 'Phone(s)',
				profile: 'Profile',
				crmv: 'CRMV',
				address: 'Address',
				noPhone: "Don't have a phone",
				noAddress: 'No address',
				noNeighborhood: 'No neighborhood',
				noComplement: 'No complement',
				noCPFCNPJ: 'No CPF/CNPJ',
				noCity: 'No city',
				noZipCode: 'No zip code',
			},

			history: {
				doesntHave: 'This client has no history yet! =D',
				note: 'In order for a history to be registered, it is enough to process some service or product on behalf',
				note2: 'of this client and the history will be computed.',
				startRequest: 'Start a request',
				createSchedule: 'Create a schedule',
				importHistory: 'Import history from another system',
			},

			exams: {
				doesntHave: 'This client has no exam yet! =D',
				note: 'For an exam to be registered, just register an exam',
				note2: 'with this client that will be computed.',
				registerExam: 'Start Exam Registration',
				importHistory: 'Import exams from another system',
			},

			agreements: {
				doesntHave: 'This Customer does not have an agreement yet! =D',
				note: 'To register the customer in an agreement, simply create a new agreement',
				note2: 'or add it to an existing one.',
				registerAgreement: 'Create a new agreement',
			},

			searchCustomers: 'Add filters by pressing "ENTER"',

			form: {
				addCustomer: 'Add Customer',
				editCustomer: 'Edit Customer',
				customerProfile: 'Customer Profile',
				registrationType: 'Registration Type',
				physicalPerson: 'Physical person',
				legalPerson: 'Legal person',
				maskActive: 'Mask activated',
				maskDesactive: 'Mask off',
				maskActiveText: 'Disable Auto Mask (If the mask is not in the format of your ID)',
				maskDesactiveText: 'Enable Auto Mask (There are two types of format)',
				issuingAgency: 'Issuing Agency',
				fullName: 'Full name',
				state: 'State',
				mainEmail: 'Main email',
				emailCollections: 'Email for Collections',
				businessPhone: 'Business Phone',
				zipCodeNotFound: "We couldn't find your address by zip code!",
				messageFirstCpf: 'Please enter CPF first.',
				requesterCRMV: "Requester's CRMV",
				municipalRegistration: 'Municipal Registration',
				fantasyName: 'Fantasy Name',
				socialReason: 'Company Name',
				requester: 'Requester',
				emailRequester: "Requester's email",
			},

			filters: {
				tutor: 'Tutor',
				doctor: 'Doctor',
				clinic: 'Clinic',
				laboratory: 'Laboratory',
				uninformed: 'Uninformed',
				placeholder: 'Registration Type',
			},

			issuingNotFound: 'Orgão não encontrado.',
			stateNotFound: 'Estado não encontrado.',
			placeholderIssuing: 'Selecione o Orgão Emissor',

			startCustomers: {
				title: "Oooops! They still don't have customer records =D",
				subtitle: 'For a request to be processed, there must be a',
				subtitle2: 'customer record\n' + '\t\t\t\tto link to the requisition.',
				registerNewCustomers: 'Register New Customer',
				importCustomers: 'Import clients from another system',
			},

			customerView: {
				backToList: 'Back to client list',
				categories: {
					historic: 'History',
					bonds: 'Bonds',
					animals: 'Animals',
					schedules: 'Schedules',
					requestedExams: 'Requested Exams',
					purchases: 'Purchases',
					documents: 'Documents',
					examReport: 'Exam Report',
					agreement: 'Agreement',
				},
			},

			tooltip: {
				viewProfile: 'View profile',
				removeCustomer: 'Remove customer',
				restoreCustomer: 'Restore customer',
				profile: 'Profile',
			},

			alert: {
				title: 'Remove Customer',
				subtitle: 'Are you sure you want to remove this client?',
			},

			feedback: {
				success: {
					create: {
						client: 'Client created successfully',
						importClient: 'Successfully imported clients',
					},

					update: {
						client: 'Client successfully updated',
					},

					remove: {
						client: 'Client successfully removed',
						importHistory: 'Import history successfully removed',
						restore: 'Client successfully restored',
					},
				},
			},
		},

		apiKey: {
			name: 'Manage API Keys',
			apiKeyAbbreviation: 'API',

			addKey: 'Add API Key',

			table: {
				keyName: 'API keys',
				tokenPrefix: 'Token prefix',
				createdBy: 'Created by',
				updatedOn: 'Updated on',
				addedIn: 'Added in',
				status: 'Status',
			},

			form: {
				addKey: 'Add API Key',
				title: 'API key created',
				subtitle: `Copy the key and use it with the parameter “api_key” in the header of your requests`,
				accessKey: 'Access key',
			},

			alert: {
				title: 'Remove API Key',
				subtitle: 'Are you sure you want to remove the api key?',
			},

			tooltip: {
				editApikey: 'Edit API Key',
				removeApikey: 'Remove API Key',
			},

			feedback: {
				successes: {
					create: {
						apikey: 'API key created successfully',
					},

					update: {
						apikey: 'API key updated successfully',
						status: 'Status updated successfully',
					},

					remove: {
						apikey: 'API key removed successfully',
					},
				},
			},
		},

		recipients: {
			name: 'Recipients',
			addRecipient: 'Add Recipient',

			descriptionPlan: 'Manage the recipients that will be used in the Exam registration.',
			knowMore: 'Know more',
			searchPlaceholder: 'Search for recipients',

			table: {
				notFound: 'No recipient records found.',
				recipient: 'Container',
				subtitle: 'Subtitle',
			},

			form: {
				addRecipient: 'Add Recipient',
				editRecipient: 'Edit Recipient',
				descriptionSample: 'Sample Description',
				subtitle: 'Subtitle',
				icon: 'Icon',
				color: 'Color',
			},

			icons: {
				pipe: 'Pipe',
				blade: 'Blade',
				bowl: 'Bowl',
				swab: 'Swab',
				drop: 'Drop',
				soda: 'Soda',
				dropper: 'Dropper',
				bullet: 'Bullet',
				flask: 'Flask',
				flaskRound: 'Flask Round',
				cylinder: 'Cylinder',
				nail: 'Nail',
				oar: 'Oar',
				rivet: 'Rivet',
				syringe: 'Syringe',
			},

			alert: {
				title: 'Remove Container',
				subtitle: 'Are you sure you want to remove this container?',
				want: 'Want',
				deleteRecipient: 'delete this recipient?',
				ifDeleteRecipient: 'If you exclude this recipient',
				requestExam: 'exam requisition',
				warningDescription:
					'all exams that contain this linked container will lose may cause\n' +
					'\t\t\t\tproblems in',
				recomendation:
					'We recommend that you create a new container and make changes to the exams\n' +
					"\t\t\t\t\twho use this container, so you don't have any problems.",
			},

			tooltip: {
				editRecipient: 'Edit recipient',
				removeRecipient: 'Remove recipient',
			},

			feedback: {
				successes: {
					create: {
						recipient: 'Recipient created successfully',
					},

					update: {
						recipient: 'Recipient updated successfully',
					},

					remove: {
						recipient: 'Recipient successfully removed',
					},
				},
			},
		},

		tags: {
			name: 'Tags',
			descriptionPlan: 'Manage the labels that will be used in the exam registration.',
			searchPlaceholder: 'Search tags',
			addTag: 'Add Tag',

			table: {
				tag: 'Tag',
				notFound: 'No record of tags found.',
			},

			form: {
				addTag: 'Add Tag',
				editTag: 'Edit Tag',
				tagName: 'Tag Name',
				preview: 'Preview',
				description: 'Description (Optional)',
			},

			tooltip: {
				editTag: 'Edit tag',
				removeTag: 'Remove tag',
			},

			alert: {
				title: 'Remove Tag',
				subtitle: 'Are you sure you want to remove this tag?',
			},

			feedback: {
				success: {
					create: {
						tag: 'Tag created successfully',
					},

					update: {
						tag: 'Tag successfully updated',
					},

					remove: {
						tag: 'Tag successfully removed',
					},
				},
			},
		},

		methods: {
			name: 'Métodos',

			addMethod: 'Add Method',

			tooltip: {
				editMethod: 'Edit method',
				removeMethod: 'Remove method',
			},

			alert: {
				title: 'Remove Method',
				subtitle: 'Are you sure you want to remove this method?',
			},

			feedback: {
				success: {
					create: {
						method: 'Method created successfully',
					},

					update: {
						method: 'Method successfully updated',
					},

					remove: {
						method: 'Method removed successfully',
					},
				},
			},
		},

		equipments: {
			name: 'Equipments',
			equipmentsAbbreviation: 'Equip.',

			equipment: 'equipment',

			form: {
				addEquipment: 'Add Equipment',
				editEquipment: 'Edit Equipment',
				equipmentName: 'Equipment name',
			},

			table: {
				equipment: 'Equipment',
			},

			alert: {
				title: 'Remove Equipment',
				subtitle: 'Are you sure you want to remove this equipment?',
			},

			tooltip: {
				editEquipment: 'Edit equipment',
				removeEquipment: 'Remove equipment',
			},

			status: {
				newInterfacing: 'New interfacing',
				inHomologation: 'In homologation',
				homologated: 'Homologated',
				waitingForApproval: 'Waiting for approval',
				interfaced: 'Interfaced',
			},

			feedback: {
				success: {
					create: {
						equipment: 'Equipment created successfully',
					},

					update: {
						equipment: 'Equipment successfully updated',
						bound: 'Equipment linked successfully',
					},

					remove: {
						equipment: 'Equipment removed successfully',
					},
				},
			},
		},

		attributes: {
			name: 'Permissions',

			table: {
				searchLabel: 'Search by permission',
				createNewAttribute: 'Create new permission',
				noDataFound: 'No attribute records found.',
				noMembersFound: 'No members record found.',
			},
			addMembers: 'Add attribute members',
			addResources: 'Add resources',
			addAllResources: 'Add all',
			editPermissions: 'Edit permissions',
			resourcesAndPermissions: 'Resource & Permissions',
			newAttribute: 'New permission',
			updateAttribute: 'Update attribute',
			notFound: 'No attribute found',
			sector: 'Sector or Department',
			functionSector: 'Function or Occupation',
			alert: {
				titleSelectGroupAttribute: 'Add Group/Attribute',
				subtitleSelectGroupAttribute: 'Select group or attribute',
				options: {
					group: 'Group',
					attribute: 'Attribute',
				},
				titleAddGroup: 'Add Group',
				titleEditGroup: 'Edit Group',
				subtitleAddGroup: 'Group Name',
				titleAddAttribute: 'Add Attribute',
				subtitleAddAttribute: 'Attribute Name',
				titleRemoveAttribute: 'Remove Attribute',
				titleEditAttribute: 'Edit attribute',
				subtitleRemoveAttribute: 'Are you sure you want to remove this attribute?',
				subtitleRemoveAttributeFormula:
					'If you delete this attribute, all formulas linked to it will also be deleted.',
				attributeTypes: {
					title: 'Type',
					simpleText: 'Simple Text',
					textarea: 'Long Text',
					integer: 'Integer',
					decimal: 'Decimal',
					formula: 'Formula',
					date: 'Date',
					positiveNegative: 'Positive/Negative',
					image: 'Image',
					select: 'Multiple Choice',
					method: 'Method',
					percentage: 'Percentage',
				},
				unit: 'Unit',
				selectOption: 'Options',
				percentage: {
					line1: 'The percentage type attribute should only be used if it is based on another integer or decimal type attribute,',
					line2: 'the sum of all attributes of the percentage type linked to the same attribute must give a total of 100%.',
				},
				percentageWarning:
					'O atributo porcentagem é exclusivo do exame de Hemograma. Para utilizar valores percentuais comuns, altere para o tipo inteiro ou decimal.',
			},
			formula: {
				title: 'Attribute information',
				attributeName: 'Attribute name',
				type: 'Type',
				unit: 'Unit',
				specie: 'Specie (Optional)',
				specieNotFound: 'Specie not found',
				attributeFormula: 'Attribute Formula',
				createFormulaText: 'Create your formula by selecting attributes and operations',
				note: 'After selecting the desired formula, you must link the exam attributes to the formula to obtain the desired result.',
				learnMore: 'Learn more',
				addOtherFormula: 'Add other formula',
				save: 'Save',
				formula: 'Formula',
				specieSelected: 'Specie',
				youAreEditing: 'You are editing this formula',
				formulaName: 'Formula name',
				selectAttribute: 'Select attribute',
			},
			dontShowTitle: "Don't show title",
			tooltip: {
				removeGroup: 'Remove group',
				editGroup: 'Edit group',
				disableGroup: 'Disable group',
				removeAttribute: 'Remove attribute',
				editAttribute: 'Edit attribute',
				addAttribute: 'Add attribute',
			},

			feedback: {
				success: {
					create: {
						attribute: 'Attribute created successfully',
						memberAttribute: 'Attribute added to member successfully',
					},

					updated: {
						attribute: 'Attribute updated successfully',
					},

					remove: {
						attribute:
							'Attribute successfully removed | Attributes successfully removed',
						memberAttribute: 'Attribute removed from member successfully',
					},
				},
			},
		},
		users: {
			table: {
				searchLabel: 'Search by members',
				noDataFound: 'No form records found.',
				filterAttribute: 'Filter by attributes',
				header: {
					attributes: 'Attributes',
					policies: 'Policies',
					invitedBy: 'Invited by',
					guestIn: 'Guest',
					lastActivity: 'Last Activity',
				},
				buttonInviteUser: 'Invite User',
				notFoundInvite: 'No invite records found.',
				notFoundMember: 'No member records found.',
			},

			alert: {
				kickMember: 'Kick Member',
				textKickMember:
					'Are you sure you want to kick this member out of the organization?',
			},

			tooltip: {
				disableStatus: 'Disable status',
				activeStatus: 'Activate status',
				activePathologist: 'Activate Pathologist',
				disablePathologist: 'Disable Pathologist',
				pathologistNotCrmv:
					'User does not have medical board record information to qualify as a pathologist',
				invalidEmail: 'Invalid email!',
				removeInvite: 'Remove Invite',
				removeInvites: 'Remove invite(s)',
				copyLinkInvite: 'Copy invitation link',
				kickUser: 'Kick member',
				kickUsers: 'Kick member(s)',
			},

			form: {
				emailAndUser: "Enter the user's email or username",
				inviteLink: 'Invitation Link',
				attributes: 'Attributes',
				policies: 'Policies',
				notifyPerson: 'Notify guests',
				notifyDescription: 'Send a notification to selected emails.',
				personalizedMessage: 'Personalized message',
				membersAlreadyInvitations:
					'Users already invited will not appear in the selection.',
			},
		},
		forms: {
			table: {
				searchLabel: 'Search by forms...',
				noDataFound: 'No form records found.',
			},
			templates: {
				startANewForm: 'Start a new form',
				modelGallery: 'Model gallery',
				inBlank: 'In blank',
				defaultTemplate: 'Default template {n}',
			},
			questionTypes: {
				shortAnswer: 'Short answer',
				paragraph: 'Paragraph',
				multipleChoice: 'Multiple choice',
				draw: 'Draw',
				checkBoxes: 'Check boxes',
				fileUpload: 'File upload',
			},
			textPlaceholders: {
				paragraph: 'Long answer text',
				text: 'Short answer text',
				date: 'Month, day, year',
				time: 'Time',
			},

			tooltip: {
				editForm: 'Edit form',
				removeForm: 'Remove form',
			},

			alert: {
				title: 'Remove Form',
				subtitle: 'Are you sure you want to remove this form?',
			},

			addOption: 'Add option',
			addOther: 'Add "other"',
			addQuestion: 'Add question',
			addSection: 'Add section',
			addTitleAndDescription: 'Add title and description',
			or: 'or',
			untitled: 'Untitled',
			untitledForm: 'Untitled form',
			untitledSection: 'Untitled section',
			withoutDescription: 'Without description',
			formWithoutDescription: 'Form without description',
			sectionWithoutDescription: 'Section without description',
			preview: 'Preview',
			description: 'Description (optional)',
			questions: 'Questions',
			question: 'Question',
			answers: 'Answers',
			section: 'Section {current} of {total}',
			option: 'Option {n}',
		},

		account: {
			profile: 'Profile',
			nickname: 'Nickname',
			cpf: 'CPF',
			gender: 'Gender',
			language: 'Language',
			personalData: 'Personal Information',
			personalAboutYou: 'Please provide basic information about yourself',
			additionalData: 'Additional Data',
			aboutYou: 'We want to know a little more about you and your daily life.',
			defaultOrganization: 'Default Organization',
			defaultOrganizationAbout:
				'The organization that will be automatically selected when accessing the platform.',
			selectOrganization: 'Select organization (Optional)',
			occupation: 'What is your occupation?',
			interestArea: 'Areas of interest',
			council: 'Council',
			register: 'Register',
			state: 'Estate',
			mapa: 'Register on the map',
			noOccupation: 'No occupation found',
			photoChanged: 'Photo successfully changed!',
			noInterest: 'No interest found',
			beCalled: 'How would you like to be called?',
			whatEmail: "What's your email address?",
			genres: {
				male: 'Male',
				female: 'Female',
				other: 'Prefer not to inform',
			},
			interests: {
				medical: 'Medical clinic',
				acupuncture: 'Acupuncture',
				anesthesia: 'Anesthesia',
				cattleRaising: 'Cattle raising',
				cardiology: 'Cardiology',
				surgicalClinic: 'Surgical Clinic',
				behavioral: 'Behavioral',
				dermatology: 'Dermatology',
				edocrinology: 'Endocrinology',
				physiotherapy: 'Physiotherapy',
				homeopathy: 'Homeopathy',
				immunology: 'Immunology',
				nephrology: 'Nephrology',
				neurology: 'Neurology',
				diagnosticMedicine: 'Diagnostic Medicine',
			},
			occupations: {
				veterinarian: 'Veterinarian',
				vetStudent: 'Student of Veterinary Medicine',
				cattleman: 'Cattleman',
				distributor: 'Shopkeeper / Distributor',
				tutor: 'Tutor',
			},
			phone: {
				title: 'My phone numbers',
				subtitle: 'View and manage all phones associated with your account.',
				addNewPhone: 'Add phone number',
				alert: {
					title: 'Add phone number',
					subtitle: 'We will send an SMS to your phone number',
					user: 'User',
					organization: 'Organization',
					phoneOrCell: 'Phone or cell number',
					smsSent: 'SMS sent to',
					validateSms: 'Validate SMS',
					resendSms: 'Resend SMS',
					validate: 'Validate',
					successPhone: 'The phone has been registered successfully!',
					successCell: 'The cell phone was successfully registered!',
					deleteMessage: {
						want: 'Do you want to',
						delete: 'delete',
						thisPhone: 'this phone?',
						thisCell: 'this cellphone?',
						this: 'This',
						deletion: 'deletion',
						willBe: 'will be',
						irreversible: 'irreversible',
						pleaseMakeSure: 'please make sure you want to delete it.',
						removedPhone: 'The phone was successfully removed.',
						removedCell: 'The cell phone was successfully removed.',
					},
					yesDelete: 'Yes, delete!',
					noDelete: "I don't want",
				},
				phone: 'Phone',
				cell: 'Cellphone',

				alertRemove: {
					title: 'Remove Phone',
					subtitle: 'Are you sure you want to remove this phone?',
				},

				feedback: {
					success: {
						create: {
							phone: 'The phone has been successfully added.',
						},

						update: {
							phone: 'The phone has been successfully updated.',
						},

						remove: {
							phone: 'The phone has been successfully removed.',
						},
					},
				},
			},
			address: {
				myAddresses: 'My addresses',
				subtitleMyAddresses: 'View and manage all addresses associated with your account.',
				addAddress: 'Add address',
				alert: {
					identify: 'How do you want to identify this address?',
				},
			},
			main: 'Main',
			choose: 'Choose',

			feedback: {
				success: {
					create: {},

					update: {
						profile: 'Profile has been successfully updated.',
					},

					remove: {},
				},
			},
		},

		organizations: {
			name: 'Organizations',
			description: "View and manage all your organization's information.",

			errorInter: 'An internal error has occurred',
			notFound: 'You do not belong to any organization.',

			addOrganization: 'Add Organization',
			loading: 'loading...',

			tooltip: {
				editOrganization: 'Edit Organization',
				removeOrganization: 'Remove Organization',
				transferOwnership: 'Transfer Ownership',
				exitOrganization: 'Leave the Organization',
			},

			form: {
				addOrganization: 'Add Organization',
				membersOrganization: 'Organization members',
			},

			alert: {
				title: 'Remove Organization',
				subtitle: 'Are you sure you want to remove this organization?',
			},

			cpfOwner: "Owner's CPF",
			ownerName: 'Owner name',
			socialReason: 'Social Reason',
			tradeName: 'Trade Name',
			personType: 'Person Type',
			organizationType: 'Organization Type',
			domain: 'Domain',
			domainInfo:
				'This domain is unique and will be used for your customers to access your Customer Area',
			imageSize:
				'The image must contain a {maxSize}. (*.PNG). Avoid leaving any white space on the sides of the image and use the logo with a transparent background.',
			maxImageSize: 'maximum of 200x70 px',
			nsfeWarning: 'Your "company" will be unable to issue NFS-e.',
			onePhysicalOrganization: 'You can only own 1 organization (individual)',

			feedback: {
				success: {
					create: {
						organization: 'The organization was successfully created.',
					},

					update: {
						organization: 'The organization was successfully updated.',
					},

					patch: {
						organization: 'You have successfully transferred the organization.',
					},

					remove: {
						organization: 'The organization was successfully removed.',
						getOutOrganization: 'You successfully exited the organization.',
					},
				},
			},
			referrer: 'Referrer by (Optional)',
		},

		importations: {
			name: 'Import / Export',
			title: 'Easily import data from other systems into ClusterVet',
			import: 'Import',
			customers: 'customers',
			descriptionImport: 'Import your list of companies/clients',
			productServices: 'products and services',
			descriptionProductServices: 'Import your exams and layout configuration',
			historyImportation: 'Import history',

			table: {
				headers: {
					file: 'File',
					module: 'Module',
					registers: 'Registers',
					imported: 'Imported',
					invalids: 'Invalids',
				},

				notFound: 'No import records found.',
			},

			form: {
				title: 'Import customers',
				selectFileCsvTsv: 'Select the file (CSV or TSV) and import to ClusterVet.',
				selectFileText: 'Select File',
				hintText: 'Allowed formats: CSV or TSV',
				ifYouPrefer: 'If you prefer,',
				downloadText: 'download our template spreadsheet',
				compartionText:
					'and compare with the import file to ensure your file\n' +
					'\t\t\t\t\t\t\tconform to standards.',
				importQuestions: 'Import questions?',
				mapFields: 'Map fields',
				checkColumnText:
					'Check that the columns of the imported file match the columns to be imported\n' +
					'\t\t\t\t\t\tfor ClusterVet.',
				fieldsClusterVet: 'ClusterVet Fields',
				columnCsv: 'CSV columns',
				required: 'required',
				selectColumn: 'Select column',
				changeFile: 'Change file',
				importing: 'Importing',
				import: 'Import',
				importInProgress: 'Import in progress',
				importInProgressDescription:
					'We are processing your import, in a moment you will be able to see the data\n' +
					'\t\t\t\t\t\timported into your list',
				seeDetails: 'See details',
				mappedFields: 'Mapped fields',
				totalColumnFile: 'Total columns in file',
				columnSelected: 'Selected columns',
				registers: 'Registers',
				fileLines: 'File lines',
				totalValidImport: 'Total valid(s) for import',
				lineErrorImport: 'Lines with error(s)',
				downloadCsv: 'Download updated CSV',
				detailsName: 'Import Details',
				searchPlaceholderDetails: 'Search by Name, E-mail, CNPJ, CPF.',
				filterBySituation: 'Filter by situation',
				filterSelecteds: 'Selected Filter',
				filters: {
					nomeInvalid: 'invalid name',
					cpfInvalid: 'invalid cpf',
					cnpjInvalid: 'invalid cnpj',
					emailInvalid: 'invalid email',
					success: 'success',
				},
				registerImported: 'Register Imported Successfully',
			},

			pastDays: 'past day(s)',
			imported: 'Imported',
			noImported: 'Not imported',

			tooltip: {
				seeDetails: 'See details',
				removeImport: 'Remove import history',
			},

			alert: {
				title: 'Remove Import History',
				subtitle: 'Are you sure you want to remove this import history?',
			},
		},
		preferences: {
			security: {
				title: 'Security',
				subtitle:
					'Change your account password frequently to prevent unauthorized access to your account.',
				emailAddress: 'Email address',
				password: 'Password',
				redefinePassword: 'Redefine password',
				newPassword: 'New password',
				confirmPassword: 'Confirm the new password',
				passMin:
					'Password must be at least 8 characters long and contain numbers and symbols',
				updatePassword: 'Update password',
				twoFactor: {
					title: 'Two-factor authentication',
					note: 'Two-factor authentication adds an extra layer of security to your account.',
					note2: 'To log in, you will also need to provide a 6-digit code.',
					soon: 'Soon',
					enable: 'Enable',
					authenticatorApps: 'Authenticator apps',
					sms: 'SMS',
					chooseMethod: 'Choose an authentication method',
					methodNote:
						'In addition to your username and password, you will have to enter a code (sent via app or SMS) to log into your account.',
					appsOption:
						'Get codes from an app like Google Authenticator, Microsoft Authenticator, Authy or 1Password',
					smsOption:
						'We will send you a code via SMS if you need to use your backup login method.',
				},
			},
			associatedAccounts: {
				title: 'Associated accounts',
				subtitle:
					'View and manage the list of accounts that are associated with your ClusterVet account.',
				connect: 'Connect',
			},
			notifications: {
				title: 'Notifications',
				subtitle:
					'View and manage the list of accounts that are associated with your ClusterVet account.',
				activity: {
					title: 'Activity',
					serviceEmail: {
						title: 'Receive an email when someone requests a service',
						subtitle:
							'When the customer requests a service, you will be notified via the registered email.',
					},
				},
				application: {
					title: 'Application',
				},
				contact: {
					title: 'Contact options',
				},
			},
			deleteAccount: {
				title: 'Delete account',
				subtitle: 'Permanently delete all account information and service data.',
				note: 'Once deleted, this account will no longer be available permanently.',
				note2: 'Please make sure you want to do this.',
				delete: 'Delete account',
			},
		},

		webcam: {
			takePhotoWithWebcam: 'Capture image with webcam',
			videoUnavailable: 'Video unavailable',
			permissionDenied:
				'Unable to access the webcam, to use this feature, enable access to the webcam through your browser.',
			takePhoto: 'Take a picture',
			takeAnotherPhoto: 'Take another picture',
		},

		webhooks: {
			integrations: 'Integrations',
			automateYourWork: 'Integrate with your favorite tools and automate your work!',
			labcloud: {
				name: 'Labcloud',
				apiKey: 'API key',
				laboratoryId: 'Laboratory ID',
				description:
					'Connect with labcloud and send exam reports directly to your clients.',
				configurations: 'Labcloud Settings',
				functions: {
					visualizeLabcloudClients: 'View labcloud clients',
					sendExamReports: 'Send exam reports',
				},
			},
			asaas: {
				name: 'Asaas',
				apiKey: 'API Key',
				walletId: 'Wallet ID',
				paymentMethod: 'Default payment method',
				configurations: 'Asaas Settings',
				description: 'Connect with asaas to send charges and receive payments.',
				functions: {
					sendPayments: 'Send charges',
					syncronization: 'Synchronization with ClusterVet invoices',
				},
			},
		},

		bills: {
			feedback: {
				success: {
					removeBillItem: 'Success in removing the item',
				},
			},
			status: {
				pending: 'Pending',
				paid: 'Paid',
				canceled: 'Canceled',
				typing: 'Typing',
				awaitingPayment: 'Awaiting Payment',
			},
		},
	},

	cropper: {
		cropImage: 'Crop image',
	},

	chips: {
		actions: {
			messageCreateItem: `Press "Enter" to create the`,
			changeColor: 'Change Color',
			rename: 'Rename',
			remove: 'Remove',
		},

		message: 'Search or create new ones',
		messageNewTag: 'Search or create new labels',
		messageSearch: 'Search the',
		messageSearchNotFound: 'No records with this attribute',

		tooltip: {
			preview: 'View labels',
		},
	},

	feedback: {
		feedback: 'Feedback',
		sendFeedback: 'Send feedback',
		describeYourProblem: 'Describe your problem or suggestion',
		howWeCanImprove: 'Tell us how we can improve our platform',
		sendImages: 'Send images',
		newScreenShot: 'New screenshot',
		screenshot: 'Screenshot',
	},

	notifications: {
		notifications: 'Notifications',
		unread: 'Unread',
		mentioned: 'I was mentioned',
		assigned: 'Assigned to me',
		nothingNew: 'Nothing new around here.',
	},

	configuration: {
		menu: {
			users: 'Users',
			profileAcess: 'Access Profile',
			integrations: 'Integrations',
			importAndExport: 'Import / Export',
			groups: 'Groups',
			agreements: 'Agreements',
			customFields: 'Custom fields',
			attributes: 'Permissions',
		},

		registers: {
			species: 'Species',
			races: 'Races',
			coats: 'Coats',
			illnesses: 'Illnesses',
			typeOfService: 'types of service',
			vaccines: 'Vaccines',
			examsAndProfiles: 'Exams / Profiles',
			category: 'Exam categories',
			forms: 'Forms',
			tags: 'Tags',
			materials: 'Materials',
			recipients: 'Recipients',
			methods: 'Methods',
			equipments: 'Equipments',
			invoices: 'Invoices',
			bills: 'Bills',
		},

		menu_user: {
			myProfile: 'My profile',
			preferences: 'Preferences',
			organizations: 'Organizations',
			rewards: 'Rewards',
		},

		menu_automations: {
			webhooks: 'Webhooks',
			triggersAndEvents: 'Triggers and Events',
			notifications: 'Notifications',
			apikey: 'API Key',
			integration: 'Integrations',
			webhook: 'Webhook',
		},

		custom_menu: {
			customFields: 'Custom fields',
			customerPortal: 'Customer portal',
			preferences: 'Preferences',
			emailTemplates: 'Email templates',
		},

		financial_menu: {
			splits: 'Splits',
			paymentGateway: 'Payment gateways',
			Preferences: 'Preferences',
		},

		menu_sessions: {
			activeSessions: 'Active sessions',
			activeTokens: 'Active tokens',
		},
	},

	menu: {
		feedback: 'Feedback',
		referer: 'Refer ClusterVet',
		help: 'Help and Support',
		invite: 'Invite members',
		office: 'Owner',
		office_personal: 'Personal Account',
		officeOwner: 'Owner',
		officeMember: 'Member',
		officeTechnicalManager: 'Technical manager',
		newSection: 'Create new section',
		editSection: 'Edit section',
		newMark: 'New mark',
		editMark: 'Edit mark',
		selectIcon: 'Select icon',
		route: 'Path/Route',
		expandedByDefault: 'Expanded by default',
		previewRoute: 'Preview route',
		clickInTheIcon: 'Click icon to select',
	},

	menu_dropdown: {
		clinica: 'Veterinary Clinic',
		laboratorio: 'Veterinary Laboratory',
		petshop: 'Petshop',
		conta: 'Account',
		explorar: 'To explore',
		organizacoes: 'My organizations',
		perfil: 'My profile',
		config: 'Settings',
		integracao: 'Integrations',
		importacao: 'Import data',
		novo: `What's new`,
		marketplace: 'Marketplace',
		clusterVetlabs: 'ClusterVet.labs',
		invite: 'Invite members',
		darkmode: 'Enable dark mode',
		lightmode: 'Enable light mode',
		sair: 'Exit',
		adicionaOrg: 'Add Organization',
	},

	resources: {
		attribute: 'Attribute',
		exam: 'Exam',
		animal: 'Animal',
		request: 'Request',
		form: 'Form',
		agreement: 'Agreement',
		examgroup: 'Exam group',
		coat: 'Coat',
		customer: 'Customer',
		examcategory: 'Exam category',
		import: 'Import',
		recipient: 'recipient',
		breed: 'Race',
		label: 'Label',
		material: 'Material',
		users: 'Users',
		species: 'Specie',
		requestedexams: 'Requested Exam',
		requestedexam: 'Requested Exam',
		method: 'Methods',
		bill: 'Bills',
		order: 'Orders',
	},

	actions: {
		create: 'Create',
		read: 'Read',
		update: 'Update',
		delete: 'Delete',
	},

	buttons: {
		save: 'Save',
		cancel: 'Cancel',
		send: 'Send',
		register: 'Register',
		saveChanges: 'Save changes',
		add: 'Add',
		kick: 'Kick out',
		remove: 'Remove',
		discard: 'Discard changes',
		ok: 'Ok',
		unlink: 'Unlink',
		next: 'Next',
		close: 'Close',
		transferOwnership: 'Transfer Ownership',
		select: 'Select',
		confirm: 'Confirm',
		printOut: 'Print Out',
		buttonUseVersion: 'Use Version',
		resetFilters: 'Reset filters',
		finalize: 'Finalize',
		invite: 'Invite User',
	},

	default: {
		updatedAt: 'Update at',
		selectAll: 'Select all',
		removeAll: 'Remove all',
		default: 'default',
		attribute: 'Attribute',
		active: 'Active',
		addedBy: 'Added by',
		attributes: 'Attributes',
		users: 'Users',
		people: 'People',
		inactive: 'Inactive',
		name: 'Name',
		edit: 'Edit',
		mandatory: 'Mandatory',
		creator: 'Creator',
		tags: 'Tags',
		remove: 'Remove',
		resources: 'Resources',
		resource: 'Resource',
		status: 'Status',
		send: 'Send',
		key: 'Key',
		value: 'Value',
		description: 'Description',
		optional: 'Optional',
		cancel: 'Cancel',
		preview: 'Preview',
		change: 'Change',
		create: 'Create',
		createdAt: 'Created at',
		creationDateAndTime: 'Creation date and time',
		fieldName: 'Field name',
		configurations: 'Configurations',
		context: 'Context',
		date: 'Date',
		duplicate: 'Duplicate',
		time: 'Time',
		forms: 'Formulários',
		freeTrial: 'You are on a free trial.',
		upgradeAccount: 'Do you want to upgrade your account?',
		addImage: 'Add image',
		changeImage: 'Change image',
		members: 'Members',
		member: 'Member',
		invites: 'Invites',
		viewMode: 'View mode',
		all: 'All',
		color: 'Color',
		financial: 'Financial',
		personalization: 'Personalization',
		permissions: 'Permissions',
		automations: 'Automations',
		registers: 'Registers',
		sessions: 'Sessions',
		back: 'Back',
		welcomeDashboard: 'My ClusterVet',
		you: 'You',
		organization: 'Organization',
		inviteUser: 'Invite users',
		unknown: 'Unknown',
		new: 'New',
		guest: 'Guest',
		selectItem: 'Item selected',
		selectItems: 'Items selected',
		none: 'None',
		view: 'View',
		amount: 'Amount',
		subtitle: 'Subtitle',
		uninformed: 'Uninformed',
		select: 'Select',
		type: 'Type',
		updateColor: 'Update color',
		notFound: 'No record',
		reset: 'Reset',
		laboratory: 'Laboratory',
		price: 'Price',
		dontCrmv: "don't have a crmv",
		doctorVeternary: 'Veterinarian',
		ok: 'Ok',
		warning: 'Warning!',
		searchTable: 'Searching...',
		knowMore: 'Know More',
		search: 'Search',
		required: 'Required',
		email: 'E-mail',
		situation: 'Situation',
		transferOwnership: 'Transfer Ownership',
		actual: 'Actual',
		owner: 'Owner',
		clinic: 'Clinic',
		zipCode: 'ZipCode',
		verifiedByClusterVet: 'Verified by ClusterVet',
		fullName: 'Full Name',
		birthDate: 'Birth Date',
		company: 'Company',
		client: 'Client',
		noPhone: 'No phone',
		verified: 'Verified',
		pending: 'Pending',
		choose: 'Choose',
		loading: 'Loading',
		entranceDate: 'Entrance Date',
		samples: 'Sample',
		requests: 'Requests',
		exams: 'Exam | Exams',
		day: 'Day',
		days: 'Days',
		hour: 'Hour',
		hours: 'Hours',
		copy: 'Copy',
		copied: 'Copied',
		reason: 'Reason',
		modeFullscreen: 'Open Full Screen Mode',
		exitModeFullScreen: 'Exit Full Screen Mode',
		selectFile: 'Select file',
		icon: 'Icon',
		created: 'Created',
		update: 'Update',
		title: 'Title',
		waiting: 'waiting',
		pixels: 'Pixels',
		line: 'Lines',
	},
}
