import API from '@/axios'
import axios from 'axios'
const EndpointRequest = '/requisicoes'
const EndpointRequest2 = '/requests'
const EndpointExamRequest = '/exam-requests'
const EndpointRequestedExams = '/requested-exams/'
const requestedExams = '/requested-exams/'
const EndpointResults = '/results'
const EndpointVersions = '/versions'
const Endpoint = '' // Temporário
const EndpointAttachments = '/attachments'
const EndpointReport = '/report'
const EndpointReports = '/reports'
const EndpointSend = '/send'
const EndpointSearch = '/search'
const EndpointModels = '/models'
const EndpointNotes = '/notes'
const EndpointRotulo = '/rotulos'

const publicApi = axios.create({
	baseURL: process.env.VUE_APP_API + '/requisicoes',
	timeout: 30000,
})

export default {
	async getVersions(id_requestedExams) {
		return await API.get(
			EndpointRequest +
				EndpointRequestedExams +
				id_requestedExams +
				EndpointResults +
				EndpointVersions
		).then((response) => response.data)
	},

	async getVersionLatest(id) {
		return await API.get(
			EndpointRequest +
				EndpointRequestedExams +
				id +
				EndpointResults +
				EndpointVersions +
				'/latest'
		).then((response) => response.data)
	},

	async uploadImageToAttributeImage(
		requestedExamId,
		attributeId,
		payload,
		cancelTokenSource,
		onUploadProgress
	) {
		const formData = new FormData()

		formData.append('image', payload.image)
		formData.append('title', payload.title)
		formData.append('description', payload.description)

		if (payload.version) {
			formData.append('version', payload.version)
		}

		return API.post(
			EndpointRequest2 +
				EndpointRequestedExams +
				`${requestedExamId}/results/attributes/${attributeId}`,
			formData,
			{
				cancelToken: cancelTokenSource.token,
				onUploadProgress,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		).then((response) => response.data)
	},

	async createNewVersion(id_exam, payload) {
		return await API.post(
			EndpointRequest +
				EndpointRequestedExams +
				id_exam +
				EndpointResults +
				EndpointVersions +
				'/latest',
			payload
		).then((response) => response.data)
	},

	async createNewVersionSpecif(id_exam, payload) {
		return await API.post(
			EndpointRequest +
				EndpointRequestedExams +
				id_exam +
				EndpointResults +
				EndpointVersions +
				'/latest',
			payload
		).then((response) => response.data)
	},

	async updateVersion(id_exam, id_version, payload) {
		return await API.patch(
			EndpointRequest +
				EndpointRequestedExams +
				id_exam +
				EndpointResults +
				EndpointVersions +
				'/' +
				id_version,
			payload
		).then((response) => response.data)
	},

	async visualizeVersion(request_exam_id, id_version) {
		return await API.get(
			EndpointRequest +
				EndpointRequestedExams +
				request_exam_id +
				EndpointResults +
				EndpointVersions +
				'/' +
				id_version
		).then((response) => response.data)
	},

	async signResult(id_exam, version, payload) {
		return await API.post(
			EndpointRequest +
				EndpointRequestedExams +
				id_exam +
				EndpointResults +
				EndpointVersions +
				'/' +
				version +
				'/sign',
			payload
		).then((response) => response.data)
	},

	async removeSignResult(id_exam, version) {
		return await API.delete(
			EndpointRequest +
				EndpointRequestedExams +
				id_exam +
				EndpointResults +
				EndpointVersions +
				'/' +
				version +
				'/sign'
		).then((response) => response)
	},

	async getAttachmentsRequest(id_request) {
		return await API.get(EndpointRequest + `/${id_request}` + EndpointAttachments).then(
			(response) => response.data
		)
	},

	async getAttachmentsExam(id_requestedExam) {
		return await API.get(
			EndpointRequest +
				`/requested-exams/${id_requestedExam}/` +
				EndpointAttachments +
				'?withExamRequest=true'
		).then((response) => response.data)
	},

	async createAttachment(
		payload,
		cancelTokenSource,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onUploadProgress
	) {
		return await API.post(EndpointRequest + EndpointAttachments, payload, {
			onUploadProgress,
			cancelToken: cancelTokenSource.token,
		}).then((response) => response.data)
	},

	async createAttachmentLaudLabcloud(payload) {
		return await API.post(EndpointRequest + EndpointAttachments, payload, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}).then((response) => response)
	},

	async removeAttachment(uuid) {
		return await API.delete(EndpointRequest + EndpointAttachments + `/${uuid}`).then(
			(response) => response
		)
	},

	async sendExamRelatory(exam_id, exam_version, payload) {
		return await API.post(
			EndpointRequest +
				EndpointRequestedExams +
				exam_id +
				EndpointResults +
				EndpointVersions +
				`/${exam_version}` +
				EndpointReport +
				EndpointSend,
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		).then((response) => response.data)
	},

	async visualizeExam(exam_id, exam_version, is_multiple) {
		return await API.get(
			EndpointRequest +
				EndpointRequestedExams +
				exam_id +
				EndpointResults +
				EndpointVersions +
				`/${exam_version}` +
				EndpointReport +
				`?concatExams=${is_multiple}`
		).then((response) => response.data)
	},

	async visualizeExamV2(exam_id, exam_version) {
		return await API.get(`pdf-generator/exams/${exam_id}/versions/${exam_version}`).then(
			(response) => {
				return response.data
			}
		)
	},

	async getExamResult(hash) {
		return await publicApi
			.get(EndpointResults + EndpointReports + '/' + hash)
			.then((response) => response)
	},

	async getExamById(id) {
		return await API.get(EndpointRequest + EndpointRequestedExams + id).then(
			(response) => response.data
		)
	},

	async searchExamsByText({ search, speciesId, examId, createdAfter, createdBefore }) {
		return await API.get(EndpointRequest + EndpointResults + EndpointSearch, {
			params: { search, speciesId, examId, createdAfter, createdBefore },
		}).then((response) => response.data)
	},

	async searchNotes({ search, page, limit, labelIds }) {
		return await API.get(EndpointModels + EndpointNotes + EndpointSearch, {
			params: { search, page, limit, labelIds },
		}).then((response) => response.data)
	},

	async createNote(payload) {
		return await API.post(EndpointModels + EndpointNotes, payload).then(
			(response) => response.data
		)
	},

	async updateNote({ id, payload }) {
		return await API.patch(EndpointModels + EndpointNotes + `/${id}`, payload).then(
			(response) => response.data
		)
	},

	async removeNote(id) {
		return await API.delete(EndpointModels + EndpointNotes + `/${id}`).then(
			(response) => response.data
		)
	},

	async addTagToNote({ tagId, noteId }) {
		return await API.post(EndpointRotulo + `/${tagId}` + EndpointNotes, {
			id: noteId,
		})
	},

	async removeTagFromNote({ tagId, noteId }) {
		return await API.delete(EndpointRotulo + `/${tagId}` + EndpointNotes, {
			data: {
				id: noteId,
			},
		})
	},

	async getAddons(request_exam_id) {
		return await API.get(EndpointModels + '/exams' + `/${request_exam_id}/addons`).then(
			(response) => response
		)
	},

	async addAddon(request_exam_id, payload) {
		return await API.post(
			EndpointModels + '/exams' + `/${request_exam_id}/addons`,
			payload
		).then((response) => response)
	},

	async updateAddon(addonId, payload) {
		return await API.patch(EndpointModels + `/addons/${addonId}`, payload).then(
			(response) => response
		)
	},

	async removeAddon(addonId) {
		return await API.delete(EndpointModels + `/addons/${addonId}`).then((response) => response)
	},

	async getAddonsLinkedByRequestedExam(request_exam_id) {
		return await API.get(
			EndpointRequest + EndpointRequestedExams + `${request_exam_id}/addons`
		).then((response) => response)
	},

	async createAddonLinkedByRequestedExam(request_exam_id, payload) {
		return await API.post(
			EndpointRequest + EndpointRequestedExams + `${request_exam_id}/addons`,
			payload
		).then((response) => response)
	},

	async updateAddonLinkedByRequestedExam(request_exam_id, payload) {
		return await API.put(
			EndpointRequest + EndpointRequestedExams + `${request_exam_id}/addons`,
			payload
		).then((response) => response)
	},

	async getExams(params) {
		return await API.get(EndpointRequest + requestedExams + 'search?', {
			params,
		}).then((response) => response)
	},

	async reportDownloadCsv(filters) {
		return await API.get(EndpointReports + '/requested-exam', { params: filters }).then(
			(response) => response
		)
	},
}
