import API from '../service'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { types as errorTypes } from '@/modules/errors/store/types'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'
import { mergeObjectsWithoutDuplicates } from '@/utils/functions/mergeObjectsWithoutDuplicates'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import i18n from '@/plugins/vue-i18n'

export default {
	namespaced: true,
	state: {
		bills: {
			data: [],
			options: {
				isLoading: true,
			},
			meta: {
				page: 0,
				limit: 5,
				totalItems: 0,
				pagesLoaded: [],
			},
		},

		customerBills: {
			data: [],
			options: {
				isLoading: true,
			},
			meta: {
				page: 0,
				limit: 3,
				totalItems: 0,
				pagesLoaded: [],
			},
		},

		selectedBill: undefined,

		billsItems: {
			loading: false,
			data: [],
		},
		billPayments: [],
		billStatus: [],
		latestBill: {},
		externalItemsBill: {
			loading: false,
			data: [],
		},
		filters: {
			orderBy: 'created_at',
			orderDirection: 'desc',
		},
		isGroupByActive: false,
		loadingCreateBill: false,
		examsSelected: [],
	},

	getters: {
		getIndex: (state) => {
			return ({ key, keyComparison = 'id', valueComparison }) => {
				return state[key].data.findIndex((bill) => bill[keyComparison] === valueComparison)
			}
		},

		getIndexBillItem: (state) => {
			return ({ keyComparison = 'id', index, valueComparison }) => {
				return state.bills.data[index].items.findIndex(
					(item) => item[keyComparison] === valueComparison
				)
			}
		},

		getBillItemIndex: (state) => {
			return ({ keyComparison = 'id', valueComparison }) => {
				return state.billsItems.data.findIndex(
					(bill) => bill[keyComparison] === valueComparison
				)
			}
		},

		getID: (state) => {
			return ({ key, id }) => {
				return state[key].data.find((bill) => bill.id === id)
			}
		},

		getIndexItemCustomerBill: (state) => {
			return ({ index, id }) => {
				return state.customerBills.data[index].bills.items.findIndex(
					(item) => item.id === id
				)
			}
		},

		getIndexItemCustomerBillItems: (state) => {
			return ({ customerIndex, billIndex, id }) => {
				return state.customerBills.data[customerIndex].bills.items[
					billIndex
				].items.findIndex((item) => item.id === id)
			}
		},

		getActiveFiltersCount: (state) => {
			const isNotAFilter = ['groupBy', 'search']

			return Object.keys(state.filters).filter(
				(key) => !isNotAFilter.includes(key) && state.filters[key]
			).length
		},

		getIndexExternalItem: (state) => {
			return (id) => {
				return state.externalItemsBill.data.findIndex(
					(externalItem) => externalItem.id === id
				)
			}
		},

		getIndexExamsSelected: (state) => {
			return (id) => {
				return state.examsSelected.findIndex((currentExam) => currentExam.id === id)
			}
		},
	},

	mutations: {
		addBill: (state, item) => {
			state.bills.data.push(item)
			state.bills.meta.totalItems += 1
		},

		updateBill: (state, { index, bill }) => {
			state.bills.data.splice(index, 1, bill)
		},

		removeBill: (state, { index }) => {
			state.bills.data.splice(index, 1)
			state.bills.meta.totalItems -= 1
		},

		updateIncrementTotalItems: (state, valueNumber) => {
			state.bills.meta.totalItems += valueNumber
		},

		updateDecrementTotalItems: (state, valueNumber) => {
			state.bills.meta.totalItems -= valueNumber
		},

		updateItemBills: (state, { index, billItem }) => {
			state.billsItems.data.splice(index, 1, billItem)
		},

		updateLoadingCreateBill: (state, boolean) => {
			state.loadingCreateBill = boolean
		},

		removeItem: (state, { index }) => {
			state.billsItems.data.splice(index, 1)
		},

		updateBillItemByList: (state, { index, indexItem, item }) => {
			state.bills.data[index].items.splice(indexItem, 1, item)
		},

		removeBillItemByList: (state, { index, indexItem }) => {
			state.bills.data[index].items.splice(indexItem, 1)
		},

		addBillsItems: (state, item) => {
			state.billsItems.data.push(item)
		},

		addCustomerBill: (state, { index, bill }) => {
			state.customerBills.data[index].bills.items.push(bill)
		},

		addCustomerBillTable: (state, bill) => {
			state.customerBills.data.push(bill)
		},

		removeCustomerBill: (state, { index, indexItem }) => {
			state.customerBills.data[index].bills.items.splice(indexItem, 1)
		},

		setReasonCustomerBillItem: (state, { index, indexItem, cancellation_reason }) => {
			state.customerBills.data[index].bills.items[indexItem].cancellation_reason =
				cancellation_reason
		},

		updateCustomerBill: (state, { index, indexItem, payload }) => {
			state.customerBills.data[index].bills.items.splice(indexItem, 1, payload)
		},

		setBillsItems: (state, items) => {
			if (!state.billsItems.data.length) {
				return (state.billsItems.data = items)
			}

			state.billsItems.data = mergeObjectsWithoutDuplicates({
				arrayLoaded: state.billsItems.data,
				newArray: items,
				keyToCompare: 'id',
			})
		},

		setBillsItemsNotMerge: (state, { items = [] }) => {
			state.billsItems.data = items
		},

		resetBillsItems: (state) => (state.billsItems.data = []),

		setSelectedBill: (state, bill) => {
			state.selectedBill = Object.assign({}, bill)
		},

		setBills: (state, { data: bills, meta }) => {
			if (bills.length) {
				state.bills.data =
					meta.page === 1
						? (state.bills.data = bills)
						: mergeObjectsWithoutDuplicates({
								arrayLoaded: state.bills.data,
								keyToCompare: 'id',
								newArray: bills,
						  })
			} else {
				state.bills.data = []
			}

			state.bills.meta = { ...state.bills.meta, ...meta }
			state.bills.options.isLoading = DISABLE_LOADING
		},

		setBillStatus: (state, status) => {
			state.billStatus = status
		},

		setLoading: (state, { key, boolean }) => (state[key].loading = boolean),

		setExternalItemsBill: (state, itemsExternal) => {
			state.externalItemsBill.data = itemsExternal
		},

		addExternalItemBill: (state, externalItem) => {
			state.externalItemsBill.data.push(externalItem)
		},

		updateExternalItemBill: (state, { index, externalItem }) => {
			state.externalItemsBill.data.splice(index, 1, externalItem)
		},

		changeStateItemBill: (state, { index, key, value }) => {
			state.billsItems.data[index][key] = value
		},

		setFilter: (state, filters) => {
			state.filters = filters
		},

		updateItemBillCustomer: (state, { indexCustomer, indexBill, item }) => {
			state.customerBills.data[indexCustomer].bills.items[indexBill] = item
		},

		updateStatusBillCustomer: (state, { indexCustomer, indexBill, status }) => {
			state.customerBills.data[indexCustomer].bills.items[indexBill].status = status
		},

		updateStatusBill: (state, { indexBill, status }) => {
			state.bills.data[indexBill].status = status
		},

		addBillItemToCustomerBill: (state, { customerIndex, billIndex, value }) => {
			state.customerBills.data[customerIndex].bills.items[billIndex].items.push(value)
		},

		addBillItemToBill: (state, { index, value }) => {
			state.bills.data[index].items.push(value)
		},

		setItemsBill: (state, { index, items }) => {
			state.bills.data[index].items = items
		},

		removeBillItemFromBill: (state, { customerIndex, billIndex, billItemIndex }) => {
			state.customerBills.data[customerIndex].bills.items[billIndex].items.splice(
				billItemIndex,
				1
			)
		},

		setBillPayments: (state, payments) => {
			state.billPayments = payments
		},

		setLatestBill: (state, bill) => {
			state.latestBill = bill
		},

		updateGroupBy: (state, boolean) => (state.isGroupByActive = boolean),

		removeBillItem: (state, { id, customerIndex }) => {
			if (customerIndex >= 0) {
				let items = state.customerBills.data[customerIndex].bills.items

				items = items.filter((e) => e.id !== id)
				state.customerBills.data[customerIndex].bills.items = items
			} else {
				state.bills.data = state.bills.data.filter((e) => e.id !== id)
			}
		},

		setCustomersBills: (state, { data: customersBills, meta }) => {
			if (customersBills.length) {
				state.customerBills.data =
					meta.page === 1
						? (state.customerBills.data = customersBills)
						: mergeObjectsWithoutDuplicates({
								arrayLoaded: state.customerBills.data,
								keyToCompare: 'customer_id',
								newArray: customersBills,
						  })
			} else {
				state.customerBills.data = []
			}

			state.customerBills.meta = { ...state.customerBills.meta, ...meta }
			state.customerBills.options.isLoading = DISABLE_LOADING
		},

		updateLoadingBills: (state, { key = 'bills', boolean }) => {
			state[key].options.isLoading = boolean
		},

		addExamSelected: (state, examSelected) => {
			state.examsSelected.push(examSelected)
		},

		updateExamsSelected: (state, examsSelected) => {
			state.examsSelected = mergeObjectsWithoutDuplicates({
				arrayLoaded: state.examsSelected,
				newArray: examsSelected,
				keyToCompare: 'id',
			})
		},

		removeExamSelected: (state, indexExamSelected) => {
			state.examsSelected.splice(indexExamSelected, 1)
		},

		resetExamsSelected: (state) => {
			state.examsSelected = []
		},

		resetBills: (state, { key = 'bills' }) => {
			state[key] = {
				data: [],
				options: {
					isLoading: true,
				},
				meta: {
					page: 0,
					limit: key === 'bills' ? 5 : 3,
					totalItems: 0,
					pagesLoaded: [],
				},
			}
		},
	},

	actions: {
		async getAllBills({ state, commit }, { limit, page, key = 'bills', groupBy = null }) {
			try {
				commit('setLoading', { key: key, boolean: ENABLE_LOADING })

				commit('updateLoadingBills', { key: key, boolean: ENABLE_LOADING })

				const { status, data: bills } = await API.getBills({
					limit,
					page,
					groupBy,
					...state.filters,
				})

				if (status === STATUS_SUCCESS) {
					const pagesLoaded =
						key === 'bills' ? [] : [...state[key].meta.pagesLoaded, page]

					const meta = {
						...bills.meta,
						page: page,
						limit: limit,
						totalItems: bills.meta.totalItems,
						pagesLoaded: pagesLoaded,
					}

					if (key === 'customerBills') {
						commit('setCustomersBills', {
							data: bills.items,
							meta: meta,
						})
					} else if (key === 'bills') {
						commit('setBills', { data: bills.items, meta: meta })
					}

					commit('setLoading', { key: key, boolean: DISABLE_LOADING })
				}

				commit('updateLoadingBills', { key: key, boolean: DISABLE_LOADING })

				return status === STATUS_SUCCESS ? bills : false
			} catch (error) {
				commit('setLoading', { key: key, boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getBillStatus({ state, commit }) {
			try {
				if (!state.billStatus.length) {
					const { status, data: billStatus } = await API.getBillStatus()

					if (status === STATUS_SUCCESS) {
						commit('setBillStatus', billStatus)
					}

					return status === STATUS_SUCCESS
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createBill({ getters, commit }, { key = 'bills', payload }) {
			try {
				const { status, data: bill } = await API.createBill(payload)

				if (status === STATUS_CREATED) {
					commit('setLatestBill', bill)

					if (key === 'customerBills') {
						const index = getters.getIndex({
							key: key,
							keyComparison: 'customer_id',
							valueComparison: bill.customer_id,
						})

						if (index > -1) {
							delete bill.items
							commit('addCustomerBill', { index: index, bill: bill })
						} else {
							const { status: status2, data: customerBill } =
								await API.visualizeCustomerBill({
									customerId: payload.customer_id,
								})

							if (status2 === STATUS_SUCCESS) {
								const indexCustomer = customerBill.items.findIndex(
									(customer) => customer.customer_id === payload.customer_id
								)

								if (indexCustomer > -1) {
									commit(
										'addCustomerBillTable',
										customerBill.items[indexCustomer]
									)
								}
							}
						}
					} else {
						commit('addBill', bill)
					}
				}

				return status === STATUS_CREATED ? bill : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async cancellationCustomerBill({ getters, commit }, { bill, payload, customerList }) {
			try {
				const { status } = await API.cancellationBill(bill.id, payload)

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndex({
						key: 'customerBills',
						keyComparison: 'customer_id',
						valueComparison: bill.customer_id,
					})

					commit('removeBillItem', { id: bill.id, customerIndex: index })
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async selectBill({ commit }, id) {
			try {
				commit('setSelectedBill', undefined)
				const { status, data: bill } = await API.visualizeBill(id)

				if (status === STATUS_SUCCESS) {
					commit('setSelectedBill', bill)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				// console.log(error)
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizeBill({ getters, commit }, { key = 'bills', id }) {
			try {
				const { status, data: bill } = await API.visualizeBill(id)

				if (status === STATUS_SUCCESS) {
					if (key === 'customerBills') {
						const index = getters.getIndex({
							key: 'customerBills',
							keyComparison: 'customer_id',
							valueComparison: bill.customer_id,
						})

						if (index > -1) {
							const indexItem = getters.getIndexItemCustomerBill({
								index: index,
								id: bill.id,
							})

							if (indexItem > -1) {
								commit('updateCustomerBill', {
									index: index,
									indexItem: indexItem,
									payload: bill,
								})
							}
						}
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateBill({ getters, commit }, { key = 'bills', id, payload }) {
			try {
				const { status, data: bill } = await API.updateBill({ id, payload })

				if (status === STATUS_SUCCESS) {
					if (key === 'customerBills') {
						const index = getters.getIndex({
							key: 'customerBills',
							keyComparison: 'customer_id',
							valueComparison: bill.customer_id,
						})

						if (index > -1) {
							const indexItem = getters.getIndexItemCustomerBill({
								index: index,
								id: bill.id,
							})

							if (indexItem > -1) {
								commit('updateCustomerBill', {
									index: index,
									indexItem: indexItem,
									payload: bill,
								})
							}
						}
					} else if (key === 'bills') {
						const indexBill = getters.getIndex({
							key: 'bills',
							keyComparison: 'id',
							valueComparison: bill.id,
						})

						if (indexBill > -1) {
							commit('updateBill', { index: indexBill, bill: bill })
						}
					}
				}

				return status === STATUS_SUCCESS ? bill : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateBillStatus({ getters, commit }, { key = 'bills', id, payload }) {
			try {
				const { status, data: bill } = await API.updateBillStatus({ id, payload })

				if (status === STATUS_SUCCESS) {
					if (key === 'customerBills') {
						const index = getters.getIndex({
							key: 'customerBills',
							keyComparison: 'customer_id',
							valueComparison: bill.customer_id,
						})

						if (index > -1) {
							const indexItem = getters.getIndexItemCustomerBill({
								index: index,
								id: bill.id,
							})

							if (indexItem > -1) {
								commit('updateCustomerBill', {
									index: index,
									indexItem: indexItem,
									payload: bill,
								})
							}
						}
					} else if (key === 'bills') {
						const indexBill = getters.getIndex({
							key: 'bills',
							keyComparison: 'id',
							valueComparison: bill.id,
						})

						if (indexBill > -1) {
							commit('updateBill', { index: indexBill, bill: bill })
						}
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateBillItem({ state, getters, commit }, { id, payload, bill }) {
			try {
				const { status, data: billItem } = await API.updateBillItem({ id, payload })

				if (status === STATUS_SUCCESS) {
					if (state.isGroupByActive) {
						const index = getters.getIndex({
							key: 'customerBills',
							keyComparison: 'customer_id',
							valueComparison: bill.customer_id,
						})

						if (index > -1) {
							const indexItem = getters.getIndexItemCustomerBill({
								index: index,
								id: bill.id,
							})

							if (indexItem > -1) {
								commit('updateCustomerBill', {
									index: index,
									indexItem: indexItem,
									payload: billItem,
								})
							}
						}
					} else {
						const indexBill = getters.getIndex({
							key: 'bills',
							keyComparison: 'id',
							valueComparison: bill.id,
						})

						if (indexBill > -1) {
							commit('updateBill', {
								index: indexBill,
								bill: billItem,
							})
						}
					}

					commit('setBillsItemsNotMerge', { items: billItem.items })
				}

				return status === STATUS_SUCCESS ? billItem : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createBillItem({ commit, getters }, { key = 'bills', id, payload, bill }) {
			try {
				const { status, data: billItem } = await API.createBillItem({ id, payload })

				if (status === STATUS_CREATED) {
					if (key === 'customerBills') {
						const index = getters.getIndex({
							key: 'customerBills',
							keyComparison: 'customer_id',
							valueComparison: bill.customer_id,
						})

						if (index > -1) {
							const indexBill = getters.getIndexItemCustomerBill({
								index: index,
								id: bill.id,
							})

							if (indexBill > -1) {
								commit('addBillItemToCustomerBill', {
									customerIndex: index,
									billIndex: indexBill,
									key,
									value: billItem,
								})
							}
						}
					} else {
						const index = getters.getIndex({
							key: 'bills',
							keyComparison: 'id',
							valueComparison: bill.id,
						})

						if (index > -1) {
							commit('addBillItemToBill', {
								index,
								value: billItem,
							})
						}
					}
				}

				return status === STATUS_CREATED
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createManyBillItem({ getters, commit, state }, { id, payload, isUpdateBill = true }) {
			try {
				const { status, data: bill } = await API.createManyBillItems({ id, payload })

				if (status === STATUS_CREATED) {
					if (state.isGroupByActive && isUpdateBill) {
						const index = getters.getIndex({
							key: 'customerBills',
							keyComparison: 'customer_id',
							valueComparison: bill.customer_id,
						})

						if (index > -1) {
							const indexItem = getters.getIndexItemCustomerBill({
								index: index,
								id: bill.id,
							})

							if (indexItem > -1) {
								commit('updateCustomerBill', {
									index: index,
									indexItem: indexItem,
									payload: bill,
								})
							}
						}
					} else if (isUpdateBill) {
						const indexBill = getters.getIndex({
							key: 'bills',
							keyComparison: 'id',
							valueComparison: bill.id,
						})

						if (indexBill > -1) {
							commit('updateBill', { index: indexBill, bill: bill })
						}
					}

					commit('setBillsItemsNotMerge', { items: bill.items })
				}

				return status === STATUS_CREATED ? bill : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createBillByCustomer({ commit }, { customerId, filters }) {
			try {
				commit('updateLoadingCreateBill', ENABLE_LOADING)

				const { status, data: bill } = await API.createBillByCustomer({
					customerId: customerId,
					payload: { ...filters },
				})

				commit('updateLoadingCreateBill', DISABLE_LOADING)

				return status === STATUS_CREATED ? bill : false
			} catch (error) {
				return false
			}
		},

		async createBillByAllCustomers({ commit }, { filters }) {
			try {
				commit('updateLoadingCreateBill', ENABLE_LOADING)

				const { status, data: bill } = await API.createBillByAllCustomers({
					payload: { ...filters },
				})

				commit('updateLoadingCreateBill', DISABLE_LOADING)

				return status === STATUS_CREATED ? bill : false
			} catch (error) {
				return false
			}
		},

		async getExternalItemsBill({ commit }) {
			try {
				commit('setLoading', { key: 'externalItemsBill', boolean: ENABLE_LOADING })
				const { status, data: externalItemsBill } = await API.getExternalItemsBill()

				if (status === STATUS_SUCCESS) {
					commit('setExternalItemsBill', externalItemsBill)
					commit('setLoading', { key: 'externalItemsBill', boolean: DISABLE_LOADING })
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit('setLoading', { key: 'externalItemsBill', boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.message, error.response, { root: true })
			}
		},

		async createExternalItemBill({ commit }, payload) {
			try {
				commit('setLoading', { key: 'externalItemsBill', boolean: ENABLE_LOADING })
				const { status, data: externalItem } = await API.createExternalItemBill(payload)

				if (status === STATUS_CREATED) {
					commit('addExternalItemBill', externalItem)
					commit('setLoading', { key: 'externalItemsBill', boolean: DISABLE_LOADING })
				}

				return status === STATUS_CREATED ? externalItem : false
			} catch (error) {
				commit('setLoading', { key: 'externalItemsBill', boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateExternalItemBill({ getters, commit }, { payload, id }) {
			try {
				commit('setLoading', { key: 'externalItemsBill', boolean: ENABLE_LOADING })
				const { status, data: externalItem } = await API.updateExternalItemBill({
					payload,
					id,
				})

				if (status === STATUS_SUCCESS) {
					const index = getters.getIndexExternalItem(id)

					if (index > -1) {
						commit('updateExternalItemBill', {
							index: index,
							externalItem: externalItem,
						})

						const indexItemBills = getters.getBillItemIndex({
							keyComparison: 'entity_id',
							valueComparison: id,
						})

						if (indexItemBills > -1) {
							const keys = [
								'entity_id',
								'entity_name',
								'value',
								'discount',
								'discount_type',
							]
							const values = ['id', 'name', 'value', 'discount', 'discount_type']

							keys.forEach((key, indexKey) => {
								commit('changeStateItemBill', {
									index: indexItemBills,
									key: key,
									value: externalItem[values[indexKey]],
								})
							})
						}
					}

					commit('setLoading', { key: 'externalItemsBill', boolean: DISABLE_LOADING })
				}

				return status === STATUS_SUCCESS ? externalItem : false
			} catch (error) {
				commit('setLoading', { key: 'externalItemsBill', boolean: DISABLE_LOADING })
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateStatusCustomerBill({ getters, commit }, { id, payload }) {
			try {
				const { status, data: bill } = await API.updateStatusBill({ id, payload })

				if (status === STATUS_SUCCESS) {
					const indexCustomer = getters.getIndex({
						key: 'customerBills',
						keyComparison: 'customer_id',
						valueComparison: bill.customer_id,
					})

					if (indexCustomer > -1) {
						const indexBill = getters.getIndexItemCustomerBill({
							index: indexCustomer,
							id: bill.id,
						})

						if (indexBill > -1) {
							commit('updateStatusBillCustomer', {
								indexCustomer: indexCustomer,
								indexBill: indexBill,
								status: bill.status,
							})
						}
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async updateStatusBill({ getters, commit }, { id, payload }) {
			try {
				const { status, data: bill } = await API.updateStatusBill({ id, payload })

				if (status === STATUS_SUCCESS) {
					const indexBill = getters.getIndex({
						key: 'bills',
						keyComparison: 'id',
						valueComparison: bill.id,
					})

					if (indexBill > -1) {
						commit('updateStatusBill', { indexBill: indexBill, status: bill.status })
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
				return false
			}
		},

		async removeBillItem({ commit, getters }, { key = 'bills', id, bill }) {
			try {
				const { status } = await API.removeBillItem({ id })

				if (status === STATUS_SUCCESS) {
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.bills.feedback.success.removeBillItem'),
						},
						{ root: true }
					)

					if (key === 'customerBills') {
						const index = getters.getIndex({
							key: 'customerBills',
							keyComparison: 'customer_id',
							valueComparison: bill.customer_id,
						})

						if (index > -1) {
							const indexBill = getters.getIndexItemCustomerBill({
								index: index,
								id: bill.id,
							})

							if (indexBill > -1) {
								const billItemIndex = getters.getIndexItemCustomerBillItems({
									customerIndex: index,
									billIndex: indexBill,
									id: id,
								})

								if (billItemIndex > -1) {
									commit('removeBillItemFromBill', {
										customerIndex: index,
										billIndex: indexBill,
										billItemIndex: billItemIndex,
									})
								}
							}
						}
					} else if (key === 'bills') {
						const indexBill = getters.getIndex({
							key: 'bills',
							keyComparison: 'id',
							valueComparison: bill.id,
						})

						if (indexBill > -1) {
							const indexBillItem = getters.getIndexBillItem({
								index: indexBill,
								valueComparison: id,
							})

							if (indexBillItem > -1) {
								commit('removeBillItemByList', {
									index: indexBill,
									indexItem: indexBillItem,
								})
							}
						}
					}

					const indexBillByBillsItems = getters.getBillItemIndex({ valueComparison: id })

					if (indexBillByBillsItems > -1) {
						commit('removeItem', { index: indexBillByBillsItems })
					}
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async removeBills({ state, commit, dispatch }, { payload }) {
			try {
				const { status } = await API.removeBills({ payload })

				if (status === STATUS_SUCCESS) {
					const FIRST_PAGE = 1
					const key = state.isGroupByActive ? 'customerBills' : 'bills'
					const LIMIT_PER_PAGE = state[key].meta.limit

					dispatch('getAllBills', {
						key: key,
						page: FIRST_PAGE,
						limit: LIMIT_PER_PAGE,
						groupBy: state.isGroupByActive ? 'customer' : null,
					})
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateAddonsToBill({ commit }, { idRequestedExam }) {
			try {
				const { status } = await API.updateAddonsToBill({
					idRequestedExam: idRequestedExam,
				})

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createBillPayment({ commit }, { payload }) {
			try {
				const { status } = await API.createBillPayment(payload)

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteBillPayment({ commit }, { id }) {
			try {
				const { status } = await API.deleteBillPayment(id)

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		removeExamSelected({ commit, getters }, id) {
			const indexExamsSelected = getters.getIndexExamsSelected(id)

			if (indexExamsSelected > -1) {
				commit('removeExamSelected', indexExamsSelected)
			}
		},
	},
}
